"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "dashboard-executive-container"
  }, [_c("div", {
    staticClass: "clearfix"
  }, [_c("pan-thumb", {
    staticStyle: {
      float: "left"
    },
    attrs: {
      image: _vm.avatar
    }
  }, [_vm._v(" Your roles: "), _vm._l(_vm.roles, function (item) {
    return _c("span", {
      key: item,
      staticClass: "info-roles"
    }, [_vm._v(_vm._s(item))]);
  })], 2), _c("div", {
    staticClass: "info-container"
  }, [_c("span", {
    staticClass: "display_name"
  }, [_vm._v(_vm._s(_vm.name))]), _c("span", {
    staticStyle: {
      "font-size": "20px",
      "padding-top": "20px",
      display: "inline-block"
    }
  }, [_vm._v("executive's Dashboard")])])], 1), _c("div", [_c("img", {
    staticClass: "emptyGif",
    attrs: {
      src: _vm.emptyGif
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;