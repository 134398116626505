"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'coupon': {
    width: 16,
    height: 16,
    viewBox: '0 0 512.001 512.001',
    data: '<path pid="0" d="M189.035 166.712c-12.308 0-22.322 10.014-22.322 22.322s10.014 22.322 22.322 22.322 22.322-10.015 22.322-22.322c0-12.308-10.014-22.322-22.322-22.322zM322.967 300.644c-12.308 0-22.322 10.014-22.322 22.322s10.014 22.322 22.322 22.322 22.322-10.014 22.322-22.322-10.015-22.322-22.322-22.322z"/><path pid="1" d="M507.154 244.22l-43.447-43.875 15.689-59.721c2.333-8.881-2.923-17.984-11.78-20.403l-59.564-16.273-16.273-59.564c-2.42-8.857-11.52-14.114-20.403-11.78l-59.72 15.69L267.78 4.846c-6.525-6.461-17.036-6.461-23.56 0l-43.875 43.448-59.721-15.689c-8.883-2.334-17.983 2.923-20.403 11.78l-16.273 59.564-59.564 16.273c-8.857 2.42-14.113 11.523-11.78 20.403l15.689 59.721L4.846 244.22c-6.461 6.525-6.461 17.036 0 23.56l43.448 43.875-15.689 59.721c-2.333 8.881 2.923 17.984 11.78 20.403l59.564 16.273 16.273 59.564c2.42 8.857 11.519 14.114 20.403 11.78l59.721-15.689 43.875 43.448a16.688 16.688 0 0011.78 4.846c4.259 0 8.518-1.615 11.78-4.846l43.875-43.448 59.721 15.689c8.882 2.331 17.983-2.923 20.403-11.78l16.273-59.564 59.564-16.273c8.857-2.42 14.113-11.523 11.78-20.403l-15.689-59.721 43.447-43.875c6.46-6.524 6.46-17.035-.001-23.56zM133.23 189.034c0-30.771 25.034-55.805 55.805-55.805s55.805 25.034 55.805 55.805-25.034 55.805-55.805 55.805-55.805-25.034-55.805-55.805zm56.481 156.93a16.69 16.69 0 01-11.837 4.904 16.682 16.682 0 01-11.837-4.904c-6.538-6.538-6.538-17.138 0-23.676L322.29 166.035c6.537-6.538 17.138-6.538 23.676 0 6.538 6.538 6.538 17.138 0 23.676L189.711 345.964zm133.256 32.807c-30.771 0-55.805-25.034-55.805-55.805s25.034-55.805 55.805-55.805 55.805 25.034 55.805 55.805-25.034 55.805-55.805 55.805z"/>'
  }
});