"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAdmin = exports.register = exports.logout = exports.login = exports.getAdminsLogins = exports.getAdmins = exports.getAdminInfo = exports.getAdminById = exports.getAdminByEmail = exports.forgotPassword = exports.deleteAdmin = exports.defaultAdminData = exports.changePassword = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultAdminData = exports.defaultAdminData = {
  id: 0,
  enabled: true,
  fullName: '',
  emailAddress: '',
  mobileNumber: '',
  password: undefined,
  avatar: '',
  adminsRoleId: 1,
  adminsRole: {
    id: null,
    name: ''
  }
};
const getAdmins = params => (0, _request.default)({
  url: '/admins',
  method: 'get',
  params
});
exports.getAdmins = getAdmins;
const getAdminById = id => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'get'
});
exports.getAdminById = getAdminById;
const getAdminInfo = data => (0, _request.default)({
  url: '/admins/me',
  method: 'post',
  data
});
exports.getAdminInfo = getAdminInfo;
const getAdminByEmail = adminemail => (0, _request.default)({
  url: `/admins/${adminemail}`,
  method: 'get'
});
exports.getAdminByEmail = getAdminByEmail;
const updateAdmin = (id, data) => (0, _request.default)({
  url: `/admins/${id}`,
  method: 'patch',
  data
});
exports.updateAdmin = updateAdmin;
const deleteAdmin = adminname => (0, _request.default)({
  url: `/admins/${adminname}`,
  method: 'delete'
});
exports.deleteAdmin = deleteAdmin;
const login = data => (0, _request.default)({
  url: '/admins/login',
  method: 'post',
  data
});
exports.login = login;
const logout = () => (0, _request.default)({
  url: '/admins/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/admins/',
  method: 'post',
  data
});
exports.register = register;
const getAdminsLogins = params => (0, _request.default)({
  url: '/admins-logins',
  method: 'get',
  params
});
exports.getAdminsLogins = getAdminsLogins;
const forgotPassword = data => (0, _request.default)({
  url: '/admins/forgot-password',
  method: 'post',
  data
});
exports.forgotPassword = forgotPassword;
const changePassword = data => (0, _request.default)({
  url: '/admins/change-password',
  method: 'post',
  data
});
exports.changePassword = changePassword;