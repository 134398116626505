"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _products = require("@/api/products");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _ProductGeneralTab = _interopRequireDefault(require("./ProductGeneralTab.vue"));
var _ProductCategoryTab = _interopRequireDefault(require("./ProductCategoryTab.vue"));
var _ProductAttributeTab = _interopRequireDefault(require("./ProductAttributeTab.vue"));
var _ProductVariationTab = _interopRequireDefault(require("./ProductVariationTab.vue"));
var _ProductInventoryTab = _interopRequireDefault(require("./ProductInventoryTab.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.formData = Object.assign({}, _products.defaultProductData);
    this.loading = false;
    this.activeIndex = 0;
    this.stepsOptions = [{
      title: 'General',
      status: 'process',
      prevStatus: 'wait',
      description: 'Description goes here',
      icon: 'el-icon-edit',
      component: _ProductGeneralTab.default
    }
    // {
    //   title: 'Category',
    //   status: 'wait',
    //   prevStatus: 'wait',
    //   description: 'Description goes here',
    //   icon: 'el-icon-notebook-2',
    //   component: ProductCategoryTab
    // },
    // {
    //   title: 'Images',
    //   status: 'wait',
    //   prevStatus: 'wait',
    //   description: 'Description goes here',
    //   icon: 'el-icon-picture',
    //   component: ProductImagesTab
    // }
    // {
    //   title: "Inventory",
    //   status: "wait",
    //   prevStatus: "wait",
    //   description: "Description goes here",
    //   icon: "el-icon-truck",
    //   component: ProductInventoryTab,
    // },
    // {
    //   title: "Advanced",
    //   status: "wait",
    //   prevStatus: "wait",
    //   description: "Description goes here",
    //   icon: "el-icon-setting",
    //   component: ProductAdvanceTab,
    // },
    ];
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      this.fetchData();
    } else {
      this.formData = Object.assign({}, _products.defaultProductData);
      this.checkIfVariationsTabRequired();
      // this.checkIfInventoryTabRequired();
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData() {
    try {
      const id = parseInt(this.$route.params && this.$route.params.id || '0');
      if (id < 1) {
        const data = await (0, _products.getProductById)(this.formData.id);
        if (data.taxId) {
          data.taxId = parseInt(data.taxId);
        }
        this.formData = data;
      } else {
        const data = await (0, _products.getProductById)(id);
        if (data.taxId) {
          data.taxId = parseInt(data.taxId);
        }
        this.formData = data;
        // if (this.formData.relatedProduct) {
        //   const selectedProduct: any = [];
        //   const productList: any = [];
        //   this.formData.relatedProduct.forEach((product: any) => {
        //     selectedProduct.push(product.id)
        //     productList.push({ id: product.id,name:product.name})
        //   })
        //   delete this.formData.relatedProduct
        //   this.formData.relatedProduct = selectedProduct
        // }
      }
      // const data: any = await getProductById(id)
      // Just for test
      const title = this.formData.name;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
      this.checkIfVariationsTabRequired();
      // this.checkIfInventoryTabRequired();
    } catch (err) {} // eslint-disable-line  no-empty
  }
  checkIfVariationsTabRequired() {
    const variationsOption = [{
      title: 'Attributes',
      status: 'wait',
      prevStatus: 'wait',
      description: 'Description goes here',
      icon: 'el-icon-document',
      component: _ProductAttributeTab.default
    }, {
      title: 'Variations',
      status: 'wait',
      prevStatus: 'wait',
      description: 'Description goes here',
      icon: 'el-icon-s-grid',
      component: _ProductVariationTab.default
    }];
    if (this.formData.productType === 'simple') {
      // check if attribute exist at index 2
      if (this.stepsOptions[2].title === variationsOption[0].title) {
        this.stepsOptions.splice(2, 2); // remove Attribute & Variation Tab
      }
    } else {
      // add only if not present
      if (this.stepsOptions[2].title !== variationsOption[0].title) {
        this.stepsOptions.splice(2, 0, variationsOption[0], variationsOption[1]);
      }
    }
  }
  checkIfInventoryTabRequired() {
    const inventoryOption = [{
      title: 'Inventory',
      status: 'wait',
      prevStatus: 'wait',
      description: 'Description goes here',
      icon: 'el-icon-truck',
      component: _ProductInventoryTab.default
    }];
    if (this.formData.productType === 'variable') {
      // check if inventory exist at index 5
      if (this.stepsOptions[5].title === inventoryOption[0].title) {
        this.stepsOptions.splice(5, 5); // remove Attribute & Variation Tab
      }
    } else {
      // add only if not present
      if (this.stepsOptions[2].title !== inventoryOption[0].title) {
        this.stepsOptions.splice(5, 0, inventoryOption[0]);
      }
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.formData.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.formData.id}`;
  }
  stepClick(index) {
    // check if not yet saved & still in first tab
    if (!this.isEdit && this.activeIndex === 0) {
      if (index > 0) {
        this.$message({
          message: 'Kindly save product before managing ' + this.stepsOptions[index].title,
          type: 'warning'
        });
      }
      return false;
    }
    this.stepsOptions[this.activeIndex].status = this.stepsOptions[this.activeIndex].prevStatus;
    this.activeIndex = index;
    this.stepsOptions[this.activeIndex].status = 'process';
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductDetail',
  components: {
    ProductGeneralTab: _ProductGeneralTab.default,
    ProductCategoryTab: _ProductCategoryTab.default
  }
})], default_1);
var _default = exports.default = default_1;