"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _common = require("@/api/common");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  async toggleEnable(value) {
    this.$confirm('Do you really want to change the status?', 'Warning', {
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(async () => {
      const newValue = !this.value;
      const data = await (0, _common.toggleEnable)(this.url, {
        enabled: newValue
      });
      if (data) {
        this.$emit('input', data.enabled);
        this.$message({
          type: 'success',
          message: 'Status Changed!'
        });
      }
    }).catch(err => {
      console.error(err);
    });
    // const newValue = !this.value
    // try {
    //   const data: any = await toggleEnable(this.url, { enabled: newValue })
    //   if (data) {
    //     this.$emit('input', data.enabled)
    //   }
    // } catch (error) {
    //   this.$message(error.message)
    // }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "value", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ''
})], default_1.prototype, "url", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Enabled'
})], default_1);
var _default = exports.default = default_1;