"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'zip': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M78.5 116.8h40.8c4.7 0 8.5-3.7 8.5-8.2V19c0-4.5-3.8-8.2-8.5-8.2H78.5V0L0 10v107.5l78.5 10.3v-11zm0-101.4h40.8c2 0 3.6 1.6 3.6 3.5v89.7c0 2-1.6 3.5-3.6 3.5H78.5V15.4zM30.3 75.8l-18.8-.5v-3l11.3-16.6v-.2l-10.2.2v-4.5l17.5-.4V54L18.7 70.8v.1l11.6.2v4.7zm9.4.2l-5.8-.2V50.7l5.8-.2V76zm22.2-11.6c-2.1 1.9-5.3 2.7-9 2.7l-2-.1v9.3l-6-.2V50.7A52 52 0 0153 50c3.9-.1 6.6.5 8.5 2 1.8 1.2 3 3.4 3 6s-.9 4.9-2.6 6.4zm-8.5-10c-.9 0-1.7.1-2.6.3v7.7l2 .2c3.4 0 5.4-1.7 5.4-4.4 0-2.4-1.7-3.8-4.8-3.7zm39.8-37h9.6v3.8h-9.6v-3.8zM83.6 23h9.6v3.8h-9.6V23zm9.6 6.2h9.6V33h-9.6v-3.8zm0 12h9.6V45h-9.6v-3.8zm-9.6-6.1h9.6V39h-9.6v-3.8zm9.5 47c2.5 0 5-1 6.7-2.6a9 9 0 002.8-6.5l-1.8-15c0-5-2.5-9.1-7.7-9.1s-7.7 4-7.7 9l-1.8 15.3a9 9 0 002.8 6.5 9.7 9.7 0 006.7 2.6zM90 65.5h6.2v12.7H90V65.5z"/>'
  }
});