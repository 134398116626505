"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _productsSubMenu = require("./subMenus/products/products-sub-menu");
const productRouter = {
  path: '/product',
  component: _index.default,
  redirect: 'noredirect',
  name: 'productManagement.title',
  meta: {
    title: 'productManagement.title',
    icon: 'product-management'
  },
  children: [..._productsSubMenu.productsSubMenu
  // ...sizesMarkupsSubMenu
  ]
};
var _default = exports.default = productRouter;