"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.sizeOptions = [{
      label: 'Default',
      value: 'default'
    }, {
      label: 'Medium',
      value: 'medium'
    }, {
      label: 'Small',
      value: 'small'
    }, {
      label: 'Mini',
      value: 'mini'
    }];
  }
  get size() {
    return _app.AppModule.size;
  }
  handleSetSize(size) {
    this.$ELEMENT.size = size;
    _app.AppModule.SetSize(size);
    this.refreshView();
    this.$message({
      message: 'Switch Size Success',
      type: 'success'
    });
  }
  refreshView() {
    // In order to make the cached page re-rendered
    _tagsView.TagsViewModule.delAllCachedViews();
    const {
      fullPath
    } = this.$route;
    this.$nextTick(() => {
      this.$router.replace({
        path: '/redirect' + fullPath
      });
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SizeSelect'
})], default_1);
var _default = exports.default = default_1;