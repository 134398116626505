"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadProductImages = exports.updateProductsPrices = exports.updateProductVariations = exports.updateProductPrice = exports.updateProductInventories = exports.updateProductImages = exports.updateProductCategories = exports.updateProductAttributes = exports.updateProduct = exports.productCount = exports.productAttributesRequest = exports.priceNotEnteredStonesCount = exports.importProduct = exports.getProductsVariations = exports.getProductsVariationById = exports.getProducts = exports.getProductById = exports.exportProducts = exports.deleteProductVariation = exports.deleteProductImages = exports.deleteProduct = exports.defaultProductData = exports.createProduct = exports.addNewProductsImages = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultProductData = exports.defaultProductData = {
  id: 0,
  enabled: true,
  name: null,
  slug: '',
  sku: '',
  productType: 'simple',
  priority: null,
  measurementUnitId: null,
  multiplier: 1,
  featured: false,
  tags: null,
  shortDescription: null,
  description: null,
  isTaxable: false,
  taxId: null,
  hsnCode: null,
  manageStock: false,
  stockStatus: 'In Stock',
  tax: {
    id: undefined
  },
  measurementUnit: {
    id: undefined
  },
  unitOfMeasurementId: null,
  brandId: null,
  brand: {
    id: undefined
  },
  category: [],
  attribute: [],
  productsImage: [],
  productsVariation: [],
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  canonicalTag: '',
  youtubeLink: '',
  relatedProduct: [],
  status: '',
  certificateNumber: '',
  sellerId: undefined,
  shapeId: undefined,
  caratWeight: undefined,
  colourId: undefined,
  clarityId: undefined,
  finishId: undefined,
  fluorescenceId: undefined,
  clicksCount: undefined,
  viewsCount: undefined,
  ctr: undefined,
  totalCartCount: undefined,
  wishlistCount: undefined,
  seller: undefined,
  shape: undefined,
  colour: undefined,
  clarity: undefined,
  finish: undefined,
  fluorescence: undefined,
  certificateDetails: '',
  cut: '',
  polish: '',
  symmetry: '',
  colorTone: false,
  isOfflineSold: false,
  offlineMarkedByType: '',
  offlineMarkedByFullName: '',
  offlineMarkedById: undefined,
  offlineMarkedTimestamp: '',
  markup: 0,
  parcelWeight: 0,
  stoneId: '',
  result: '',
  diamondPieces: 0,
  type: '',
  priceEditing: false,
  soldOfflineEditing: false,
  savingPrice: false,
  originalIsOfflineSold: undefined
};
const getProducts = params => (0, _request.default)({
  url: '/products',
  method: 'get',
  params
});
exports.getProducts = getProducts;
const getProductById = id => (0, _request.default)({
  url: `/products/${id}`,
  method: 'get'
});
exports.getProductById = getProductById;
const updateProduct = (id, data) => (0, _request.default)({
  url: `/products/${id}`,
  method: 'patch',
  data
});
exports.updateProduct = updateProduct;
const deleteProduct = id => (0, _request.default)({
  url: `/products/${id}`,
  method: 'delete'
});
exports.deleteProduct = deleteProduct;
const createProduct = data => (0, _request.default)({
  url: '/products/',
  method: 'post',
  data
});
exports.createProduct = createProduct;
const productCount = params => (0, _request.default)({
  url: '/products/stonesCount',
  method: 'get',
  params
});
exports.productCount = productCount;
const updateProductCategories = (id, data) => (0, _request.default)({
  url: `/products/categories/${id}`,
  method: 'patch',
  data
});
exports.updateProductCategories = updateProductCategories;
const updateProductAttributes = (id, data) => (0, _request.default)({
  url: `/products/attributes/${id}`,
  method: 'patch',
  data
});
exports.updateProductAttributes = updateProductAttributes;
const updateProductVariations = (id, data) => (0, _request.default)({
  url: '/products-variations/manage',
  method: 'post',
  data
});
exports.updateProductVariations = updateProductVariations;
const deleteProductVariation = id => (0, _request.default)({
  url: `/products-variations/${id}`,
  method: 'delete'
});
exports.deleteProductVariation = deleteProductVariation;
const updateProductImages = (id, data) => (0, _request.default)({
  url: `/products/images/${id}`,
  method: 'patch',
  data
});
exports.updateProductImages = updateProductImages;
const deleteProductImages = id => (0, _request.default)({
  url: `/products-images/${id}`,
  method: 'delete'
});
exports.deleteProductImages = deleteProductImages;
const updateProductInventories = (id, data) => (0, _request.default)({
  url: `/products/inventories/${id}`,
  method: 'patch',
  data
});
exports.updateProductInventories = updateProductInventories;
const uploadProductImages = data => (0, _request.default)({
  url: '/products/images',
  method: 'post',
  data
});
exports.uploadProductImages = uploadProductImages;
const importProduct = data => (0, _request.default)({
  url: '/products/singleImport',
  method: 'post',
  data
});
exports.importProduct = importProduct;
const getProductsVariations = params => (0, _request.default)({
  url: '/products-variations',
  method: 'get',
  params
});
exports.getProductsVariations = getProductsVariations;
const updateProductsPrices = data => (0, _request.default)({
  url: '/products-variations/singleImport',
  method: 'post',
  data
});
exports.updateProductsPrices = updateProductsPrices;
const exportProducts = () => (0, _request.default)({
  url: '/products/export',
  method: 'get'
});
exports.exportProducts = exportProducts;
const getProductsVariationById = id => (0, _request.default)({
  url: `/products-variations/${id}`,
  method: 'get'
});
exports.getProductsVariationById = getProductsVariationById;
const addNewProductsImages = data => (0, _request.default)({
  url: '/products-images',
  method: 'post',
  data
});
exports.addNewProductsImages = addNewProductsImages;
const updateProductPrice = data => (0, _request.default)({
  url: '/products-variations/update-unit-price',
  method: 'post',
  data
});
exports.updateProductPrice = updateProductPrice;
const productAttributesRequest = (url, params) => (0, _request.default)({
  url: '/' + url,
  method: 'get',
  params
});
exports.productAttributesRequest = productAttributesRequest;
const priceNotEnteredStonesCount = params => (0, _request.default)({
  url: '/products/stones-price-not-entered',
  method: 'get',
  params
});
exports.priceNotEnteredStonesCount = priceNotEnteredStonesCount;