"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _measurementUnit = require("@/api/master/measurement-unit");
var _utils = require("@/utils");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {};
    this.unitOfMeasurements = [];
    this.taxes = [];
  }
  submitForm() {
    this.$refs.formData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  created() {
    this.getMeasurementUnits();
  }
  handleProductTypeChange() {
    this.$emit('updated', this.formData);
  }
  async getMeasurementUnits() {
    try {
      this.unitOfMeasurements = await (0, _measurementUnit.getMeasurementUnits)((0, _utils.getQuery)({
        enabled: true,
        filter: {
          enabled: 'eq'
        }
      }));
    } catch (err) {} // eslint-disable-line  no-empty
  }
  async saveForm() {
    this.loading = true;
    try {
      const response = await (0, _products.updateProduct)(this.formData.id, this.formData);
      this.formData = response;
      this.$notify({
        title: 'Success',
        message: 'saved',
        type: 'success',
        duration: 2000
      });
      this.$emit('updated', this.formData);
    } catch (err) {
      this.$notify.error({
        title: 'Failed to save',
        message: err
      });
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.formData.resetFields();
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductInventoryTab',
  components: {
    HelpText: _index.default
  }
})], default_1);
var _default = exports.default = default_1;