"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _seller = require("@/store/modules/seller");
var _Account = _interopRequireDefault(require("./components/Account.vue"));
const defaultProfile = {
  name: 'Loading...',
  email: 'Loading...',
  avatar: 'Loading...',
  roles: 'Loading...'
};
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.user = defaultProfile;
    this.activeTab = 'account';
  }
  get name() {
    return _seller.SellerModule.firstName + _seller.SellerModule.lastName;
  }
  get email() {
    return _seller.SellerModule.email;
  }
  get avatar() {
    return _seller.SellerModule.avatar;
  }
  get roles() {
    return _seller.SellerModule.roles;
  }
  created() {
    this.getUser();
  }
  getUser() {
    this.user = {
      name: this.name,
      email: this.email,
      avatar: this.avatar,
      roles: this.roles.join(' | ')
    };
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Profile',
  components: {
    Account: _Account.default
  }
})], default_1);
var _default = exports.default = default_1;