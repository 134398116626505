"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _permission = require("@/store/modules/permission");
var _settings = require("@/store/modules/settings");
var _SidebarItem = _interopRequireDefault(require("./SidebarItem.vue"));
var _SidebarLogo = _interopRequireDefault(require("./SidebarLogo.vue"));
var _variables = _interopRequireDefault(require("@/styles/_variables.scss"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  get sidebar() {
    return _app.AppModule.sidebar;
  }
  get routes() {
    return _permission.PermissionModule.routes;
  }
  get showLogo() {
    return _settings.SettingsModule.showSidebarLogo;
  }
  get menuActiveTextColor() {
    if (_settings.SettingsModule.sidebarTextTheme) {
      return _settings.SettingsModule.theme;
    } else {
      return _variables.default.menuActiveText;
    }
  }
  get variables() {
    return _variables.default;
  }
  get activeMenu() {
    const route = this.$route;
    const {
      meta,
      path
    } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta.activeMenu) {
      return meta.activeMenu;
    }
    return path;
  }
  get isCollapse() {
    return !this.sidebar.opened;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SideBar',
  components: {
    SidebarItem: _SidebarItem.default,
    SidebarLogo: _SidebarLogo.default
  }
})], default_1);
var _default = exports.default = default_1;