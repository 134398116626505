"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'listed-stones': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M281.6 351.9l16.3-54.4M374.6 297.5l16.3 54.4M336.3 479.2L390.9 352H281.6l54.7 127.2z" fill-rule="evenodd" clip-rule="evenodd" _fill="none" _stroke="#000" stroke-width="19" stroke-miterlimit="10"/><g _fill="#231f20"><path pid="1" d="M201 45c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zM86 173.5h230c5.5 0 10-4.5 10-10s-4.5-10-10-10H86c-5.5 0-10 4.5-10 10s4.5 10 10 10zM86 217.5h149.8c5.5 0 10-4.5 10-10s-4.5-10-10-10H86c-5.5 0-10 4.5-10 10s4.5 10 10 10zM86 262.5h52.1c5.5 0 10-4.5 10-10s-4.5-10-10-10H86c-5.5 0-10 4.5-10 10s4.5 10 10 10zM86 307.5h84.1c5.5 0 10-4.5 10-10s-4.5-10-10-10H86c-5.5 0-10 4.5-10 10s4.5 10 10 10zM86 382.5h52.1c5.5 0 10-4.5 10-10s-4.5-10-10-10H86c-5.5 0-10 4.5-10 10s4.5 10 10 10zM76 417.5c0 5.5 4.5 10 10 10h104.1c5.5 0 10-4.5 10-10s-4.5-10-10-10H86c-5.5 0-10 4.5-10 10z"/><path pid="2" d="M483.9 345.8l-41.7-54.4c-1.9-2.5-4.8-3.9-7.9-3.9H376V75c0-5.5-4.5-10-10-10h-70V55c0-5.5-4.5-10-10-10h-30.9C250.4 19.4 227.9 0 201 0s-49.4 19.4-54.1 45H116c-5.5 0-10 4.5-10 10v10H36c-5.5 0-10 4.5-10 10v397c0 5.5 4.5 10 10 10h268l25 26.8c1.9 2 4.5 3.2 7.3 3.2s5.4-1.2 7.3-3.2l139.7-150.1c3.3-3.5 3.6-9 .6-12.9zm-28.2-3.9H216.8l26.4-34.4h186.2l26.3 34.4zM126 65h30c5.5 0 10-4.5 10-10 0-19.3 15.7-35 35-35s35 15.7 35 35c0 5.5 4.5 10 10 10h30v35H126V65zM46 462V85h60v25c0 5.5 4.5 10 10 10h170c5.5 0 10-4.5 10-10V85h60v202.5H238.2c-3.1 0-6 1.4-7.9 3.9l-41.7 54.4c-3 3.9-2.7 9.3.6 12.9L285.4 462H46zm290.3 25.3L219.5 361.9H453L336.3 487.3z"/></g>'
  }
});