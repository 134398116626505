"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateMeasurementUnits = exports.getMeasurementUnitsById = exports.getMeasurementUnits = exports.fileTransferHeaders = exports.deleteMeasurementUnits = exports.defaultMeasurementUnitsData = exports.createMeasurementUnits = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultMeasurementUnitsData = exports.defaultMeasurementUnitsData = {
  id: 0,
  enabled: true,
  name: '',
  code: ''
};
const getMeasurementUnits = params => (0, _request.default)({
  url: '/measurement-units',
  method: 'get',
  params
});
exports.getMeasurementUnits = getMeasurementUnits;
const getMeasurementUnitsById = id => (0, _request.default)({
  url: `/measurement-units/${id}`,
  method: 'get'
});
exports.getMeasurementUnitsById = getMeasurementUnitsById;
const updateMeasurementUnits = (id, data) => (0, _request.default)({
  url: `/measurement-units/${id}`,
  method: 'patch',
  data
});
exports.updateMeasurementUnits = updateMeasurementUnits;
const deleteMeasurementUnits = id => (0, _request.default)({
  url: `/measurement-units/${id}`,
  method: 'delete'
});
exports.deleteMeasurementUnits = deleteMeasurementUnits;
const createMeasurementUnits = data => (0, _request.default)({
  url: '/measurement-units/',
  method: 'post',
  data
});
exports.createMeasurementUnits = createMeasurementUnits;