"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyOtp = exports.updateSellersUser = exports.requestOtp = exports.getSellersUsers = exports.getSellersUserInfo = exports.getSellersUserById = exports.deleteSellersUser = exports.defaultSellersUserData = exports.createSellersUser = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSellersUserData = exports.defaultSellersUserData = {
  id: 0,
  enabled: true,
  firstName: '',
  lastName: '',
  mobileNumber: '',
  mobileNumberVerified: false,
  emailAddress: '',
  emailAddressVerified: false,
  gender: '',
  avatar: '',
  sellersUsersRoleId: undefined,
  sellerId: undefined,
  lastLogin: '',
  sellersUsersRole: {
    id: undefined,
    name: ''
  },
  seller: undefined
};
const getSellersUsers = params => (0, _request.default)({
  url: '/sellers-users',
  method: 'get',
  params
});
exports.getSellersUsers = getSellersUsers;
const getSellersUserInfo = data => (0, _request.default)({
  url: '/sellers-users/me',
  method: 'post',
  data
});
exports.getSellersUserInfo = getSellersUserInfo;
const getSellersUserById = id => (0, _request.default)({
  url: `/sellers-users/${id}`,
  method: 'get'
});
exports.getSellersUserById = getSellersUserById;
const updateSellersUser = (id, data) => (0, _request.default)({
  url: `/sellers-users/${id}`,
  method: 'patch',
  data
});
exports.updateSellersUser = updateSellersUser;
const deleteSellersUser = id => (0, _request.default)({
  url: `/sellers-users/${id}`,
  method: 'delete'
});
exports.deleteSellersUser = deleteSellersUser;
const createSellersUser = data => (0, _request.default)({
  url: '/sellers-users/',
  method: 'post',
  data
});
exports.createSellersUser = createSellersUser;
const requestOtp = data => (0, _request.default)({
  url: '/sellers-users/requestOtp',
  method: 'post',
  data
});
exports.requestOtp = requestOtp;
const verifyOtp = data => (0, _request.default)({
  url: '/sellers-users/verifyOtp',
  method: 'post',
  data
});
exports.verifyOtp = verifyOtp;