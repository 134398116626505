"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
var _errorLog = require("@/store/modules/error-log");
var _validate = require("@/utils/validate");
var _settings = _interopRequireDefault(require("@/settings"));
const {
  errorLog: needErrorLog
} = _settings.default;
const checkNeed = () => {
  const env = process.env.NODE_ENV;
  if ((0, _validate.isArray)(needErrorLog) && env) {
    return needErrorLog.includes(env);
  }
  return false;
};
if (checkNeed()) {
  _vue.default.config.errorHandler = function (err, vm, info) {
    _errorLog.ErrorLogModule.AddErrorLog({
      err,
      vm,
      info,
      url: window.location.href
    });
  };
}