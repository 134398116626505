"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'stone-ordered': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M492.6 163.4h-72V19.3c0-5.7-4.6-10.3-10.3-10.3H101.7C96 9 91.4 13.6 91.4 19.3v82.3c0 5.7 4.6 10.3 10.3 10.3s10.3-4.6 10.3-10.3V81h288.1v82.3h-51.5c-5.7 0-10.3 4.6-10.3 10.3v144.1c0 5.7 4.6 10.3 10.3 10.3h51.5v103H112v-20.6c0-5.7-4.6-10.3-10.3-10.3s-10.3 4.6-10.3 10.3v82.3c0 5.7 4.6 10.3 10.3 10.3h308.6c5.7 0 10.3-4.6 10.3-10.3V328h72c5.7 0 10.3-4.6 10.3-10.3v-144c0-5.7-4.6-10.3-10.3-10.3zM112 60.6V29.7h288.1v30.9H112zm0 421.7v-30.9h288.1v30.9H112zM482.3 184v20.6H358.9V184h123.4zM358.9 307.5v-82.3h123.5v82.3H358.9z"/><path pid="1" d="M407.3 248.7c-4-4-10.5-4-14.5 0L382.5 259c-4 4-4 10.5 0 14.5l10.3 10.3c4 4 10.5 4 14.5 0s4-10.5 0-14.5l-3-3 3-3c4-4 4-10.6 0-14.6zM448.5 248.7c-4-4-10.5-4-14.5 0s-4 10.5 0 14.5l3 3-3 3c-4 4-4 10.5 0 14.5s10.5 4 14.5 0l10.3-10.3c4-4 4-10.5 0-14.5l-10.3-10.2zM90.8 324.5c-1.4-4-5.1-6.7-9.5-6.8h-62C13.6 317.7 9 322.3 9 328c0 67.2-.2 62.7.4 64.7 1.1 3.9 4.7 7.1 9.9 7.3h61.8c5.7 0 10.3-4.6 10.3-10.3 0-65.4.2-62.8-.6-65.2zm-34.6 13.8L29.7 365v-26.7h26.5zm-12 41.2l26.6-26.6v26.6H44.2zM17.9 297.1c1.1.2-3.3.1 63.2.1 5.7 0 10.3-4.6 10.3-10.3 0-66.7.1-62.4-.2-63.7-.5-3.2-3-6.5-7.1-7.8-2.1-.6 2.2-.4-64.7-.4-5.7 0-10.3 4.6-10.3 10.3v61.8c0 4.8 3.7 9.2 8.8 10zm26.3-20.5L70.8 250v26.6H44.2zm12-41.2L29.7 262v-26.6h26.5zM15.9 193.6c2.4.8-1.9.5 65.1.6 5.7 0 10.3-4.6 10.3-10.3v-61.7c0-4.7-3.4-9.3-8.9-10.1-1.2-.2-1.7-.1-63.2-.1-5.6 0-10.2 4.7-10.2 10.3V184c.1 4.2 2.8 8.2 6.9 9.6zm28.3-19.9l26.6-26.6v26.6H44.2zm12-41.2l-26.6 26.6v-26.6h26.6z"/><path pid="2" _stroke="#000" stroke-width="9" stroke-miterlimit="10" d="M306 230.9l-61.7 91.7 25.3-91.7zM245.5 231.1l-29.2 105.5-30.6-105.3zM197.2 205.2l19.1-22.6 18.8 22.6zM161.5 231.3l25.9 88.7-59.7-88.7zM305.2 205l-38.4.2c-.2-.2-.2-.2-.3-.4l-24.5-29 41.7-.4 21.5 29.6zM148.7 176.7l41.1-.4-24.1 29.1h-37.2z"/>'
  }
});