// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.sidebar-container .horizontal-collapse-transition {\n  transition: 0s width ease-in-out, 0s padding-left ease-in-out, 0s padding-right ease-in-out;\n}\n.sidebar-container .scrollbar-wrapper {\n  overflow-x: hidden !important;\n}\n.sidebar-container .el-scrollbar__view {\n  height: 100%;\n}\n.sidebar-container .el-scrollbar__bar.is-vertical {\n  right: 0px;\n}\n.sidebar-container .el-scrollbar__bar.is-horizontal {\n  display: none;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#0a3378",
	"menuText": "#fff",
	"menuActiveText": "#409eff"
};
module.exports = exports;
