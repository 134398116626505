"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-row", {
    staticClass: "panel-group padding-left",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      "el-col": "",
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/product/list"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "listed-stones"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Listed Stones / Parcel ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.productscount,
      duration: _vm.animationDuration
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      "el-col": "",
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "stone-ordered"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Stones Ordred ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.stonesOrdered,
      duration: _vm.animationDuration
    }
  })], 1)])]), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      "el-col": "",
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("router-link", {
    attrs: {
      to: "/product/list?price=0"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "Price-not-enterted"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Price Not Entered ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.priceNotEntered,
      duration: _vm.animationDuration
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      "el-col": "",
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon"
  }, [_c("svg-icon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "stone-in-cart"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Stones In Cart ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.stonesInCart,
      duration: _vm.animationDuration
    }
  })], 1)])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;