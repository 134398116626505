"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "formData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.formData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_vm.formData.productType !== "simple" ? _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "SKU"
    }
  }, [_c("el-input", {
    attrs: {
      name: "sku",
      placeholder: "SKU"
    },
    model: {
      value: _vm.formData.sku,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "sku", $$v);
      },
      expression: "formData.sku"
    }
  }), _c("help-text", {
    attrs: {
      content: "SKU refers to a Stock Keeping unit, a unique identifier for each distinct product"
    }
  })], 1) : _vm._e(), _vm.formData.productType !== "variable" ? _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Manage Stock"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.productsVariation[0].manageStock,
      callback: function ($$v) {
        _vm.$set(_vm.formData.productsVariation[0], "manageStock", $$v);
      },
      expression: "formData.productsVariation[0].manageStock"
    }
  }), _c("help-text", {
    attrs: {
      content: "Enable stock management at product level"
    }
  })], 1) : _vm._e(), _vm.formData.productType === "simple" && !_vm.formData.productsVariation[0].manageStock ? _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Stock Status"
    }
  }, [_c("el-switch", {
    attrs: {
      size: "mini",
      "active-text": "In Stock",
      "inactive-text": "Out Of Stock",
      "active-value": "In Stock",
      "inactive-value": "Out Of Stock"
    },
    model: {
      value: _vm.formData.productsVariation[0].stockStatus,
      callback: function ($$v) {
        _vm.$set(_vm.formData.productsVariation[0], "stockStatus", $$v);
      },
      expression: "formData.productsVariation[0].stockStatus"
    }
  }), _c("help-text", {
    attrs: {
      content: "Enable stock management at product level"
    }
  })], 1) : _vm._e(), _vm.formData.productType === "simple" && _vm.formData.productsVariation[0].manageStock ? [_c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Stock Quantity"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "stockQuantity",
      placeholder: "Stock Quantity",
      type: "number",
      min: 0,
      "controls-position": "right",
      precision: 0
    },
    model: {
      value: _vm.formData.productsVariation[0].stockQuantity,
      callback: function ($$v) {
        _vm.$set(_vm.formData.productsVariation[0], "stockQuantity", $$v);
      },
      expression: "formData.productsVariation[0].stockQuantity"
    }
  }), _c("help-text", {
    attrs: {
      content: ""
    }
  })], 1), _c("el-form-item", {
    staticClass: "half-width",
    attrs: {
      label: "Allow backorders?"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.formData.allowBackorders,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "allowBackorders", $$v);
      },
      expression: "formData.allowBackorders"
    }
  }), _c("help-text", {
    attrs: {
      content: "If managing stock, this controls whether or not backorders are allowed. If enabled, stock quantity can go below 0"
    }
  })], 1)] : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;