"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'diamond-polish': {
    width: 32.001,
    height: 32.001,
    viewBox: '0 0 846.66 846.66',
    data: '<path pid="0" d="M361.04 191.12c46.05 0 209.9 4.86 225.71 60.01l80.53 113.61c5.92 8.35 7.68 17.68.91 26.33l-142.72 203.9H663.8c11.25 0 20.37 9.12 20.37 20.37v146.77c0 42.21-34.46 76.67-76.67 76.67H114.58c-42.21 0-76.67-34.46-76.67-76.67V615.34c0-11.25 9.12-20.37 20.37-20.37h138.33L54.15 391.43c-6.05-8.7-6.27-16.92-.03-25.72l81.21-114.58c15.81-55.15 179.66-60.01 225.71-60.01zM688.54 22.3l24.86 80.93 81.07 24.9c19.25 5.92 18.87 33.16-.14 38.87l-80.93 24.86-24.91 81.08c-5.91 19.24-33.16 18.86-38.87-.15l-24.86-80.93-81.07-24.9c-19.25-5.92-18.87-33.16.14-38.87l80.93-24.86 24.91-81.08c5.91-19.24 33.16-18.86 38.87.15zm-10.81 103.15l-8.65-28.17-8.61 28.02c-1.88 6.27-6.76 11.52-13.49 13.59l-28.17 8.66 28.03 8.6c6.27 1.89 11.52 6.76 13.59 13.5l8.65 28.16 8.61-28.02c1.88-6.27 6.76-11.52 13.49-13.59l28.17-8.65-28.03-8.61c-6.27-1.89-11.52-6.76-13.59-13.49zM78.65 669.82h364.78c26.79 0 26.79 40.74 0 40.74H78.65v51.55c0 19.72 16.21 35.93 35.93 35.93H607.5c19.72 0 35.93-16.21 35.93-35.93v-51.55H531.58c-26.79 0-26.79-40.74 0-40.74h111.85v-34.11H78.65zm397.3-74.85l107.54-153.65c-122.34 44.92-317.04 46.94-444.9 0l107.54 153.65h41.61l-42.84-60.33c-15.47-21.74 17.63-45.29 33.1-23.55l59.56 83.88h46.96l59.57-83.88c15.46-21.74 48.56 1.81 33.1 23.55l-42.85 60.33zm-135.28-79.05c0-26.79 40.74-26.79 40.74 0v34.9c0 26.79-40.74 26.79-40.74 0zm40.74-189.06v107.65c44.56-1.08 88.38-5.72 127.65-13.92l-52.33-98.8c-23.09 2.71-48.51 4.48-75.32 5.07zm-40.74 107.65V326.86c-23.11-.51-45.2-1.89-65.65-4.01l-57.97 98.56c38.23 7.69 80.58 12.06 123.62 13.1zm158.57-119.29l50.37 95.11c30.16-9.15 56.03-20.87 75.27-35.15l-59.08-83.37c-19.06 12.42-44.27 19-66.56 23.41zm-323.35 96.13l55.58-94.51c-24.93-4.44-54-11.23-75.19-25.03L97.2 375.18c19.96 14.82 47.06 26.88 78.69 36.17zm185.15-179.5c-15.53 0-186.1 10.93-186.1 27.25 0 16.33 170.57 27.26 186.1 27.26 15.54 0 186.11-10.93 186.11-27.26 0-16.32-170.57-27.25-186.11-27.25z" fill-rule="nonzero"/>'
  }
});