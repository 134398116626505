"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  route: {
    dashboard: 'Dashboard',
    documentation: 'Documentation',
    guide: 'Guide',
    permission: 'Permission',
    pagePermission: 'Page Permission',
    rolePermission: 'Role Permission',
    directivePermission: 'Directive Permission',
    icons: 'Icons',
    components: 'Components',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Editor',
    splitPane: 'SplitPane',
    avatarUpload: 'Avatar Upload',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: 'Mixin',
    backToTop: 'Back To Top',
    draggableDialog: 'Draggable Dialog',
    draggableKanban: 'Draggable Kanban',
    draggableList: 'Draggable List',
    draggableSelect: 'Draggable Select',
    charts: 'Charts',
    barChart: 'Bar Chart',
    lineChart: 'Line Chart',
    mixedChart: 'Mixed Chart',
    example: 'Example',
    nested: 'Nested Routes',
    menu1: 'Menu 1',
    'menu1-1': 'Menu 1-1',
    'menu1-2': 'Menu 1-2',
    'menu1-2-1': 'Menu 1-2-1',
    'menu1-2-2': 'Menu 1-2-2',
    'menu1-3': 'Menu 1-3',
    menu2: 'Menu 2',
    table: 'Table',
    dynamicTable: 'Dynamic Table',
    draggableTable: 'Draggable Table',
    inlineEditTable: 'Inline Edit Table',
    complexTable: 'Complex Table',
    tab: 'Tab',
    form: 'Form',
    createArticle: 'Create Article',
    editArticle: 'Edit Article',
    articleList: 'Article List',
    errorPages: 'Error Pages',
    page401: '401',
    page404: '404',
    errorLog: 'Error Log',
    excel: 'Excel',
    exportExcel: 'Export Excel',
    selectExcel: 'Export Selected',
    mergeHeader: 'Merge Header',
    uploadExcel: 'Upload Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: 'Theme',
    clipboard: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'External Link',
    profile: 'Profile',
    adminManagement: {
      title: 'Admin Management',
      admins: {
        title: 'Admins',
        add: 'Add Admin',
        edit: 'Edit Admin',
        changePassword: 'Change Password'
      },
      roles: {
        title: 'Roles',
        manage: 'Manage Role'
      }
    },
    userManagement: {
      title: 'Customer Management',
      users: {
        title: 'Customer',
        add: 'Add Customer',
        edit: 'Edit Customer'
      }
    },
    reviews: {
      title: 'Feedback',
      name: 'Feedback'
    },
    marketing: {
      title: 'Marketing',
      name: 'Marketing'
    },
    settingManagement: {
      title: 'Setting Management',
      settings: {
        title: 'Setting',
        add: 'Add Setting',
        edit: 'Edit Setting'
      }
    },
    bannerManagement: {
      title: 'Banner Management',
      banners: {
        title: 'Banner',
        add: 'Add Banner',
        edit: 'Edit Banner'
      }
    },
    productManagement: {
      title: 'Product Management',
      brand: {
        title: 'Brand',
        add: 'Add Brand',
        manage: 'Manage Brand'
      },
      attribute: {
        title: 'Attribute',
        add: 'Add Attribute',
        edit: 'Edit Attribute'
      },
      attributevalue: {
        title: 'Attribute Value',
        add: 'Add Attribute Value',
        edit: 'Edit Attribute Value',
        manage: 'Manage Attribute Value'
      },
      product: {
        title: 'Product',
        manage: 'Manage Product',
        import: 'Import Products',
        updatePrice: 'Update Product Prices'
      },
      category: {
        title: 'Category',
        manage: 'Manage Category',
        edit: 'Edit Category',
        add: 'Add Category'
      },
      // menus: {
      //   title: 'Menu',
      //   add: 'Add Menu',
      //   manage: 'Manage Menu'
      // },
      horsePower: {
        title: 'HP',
        add: 'Add HP',
        manage: 'Manage HP'
      },
      shape: {
        title: 'Shape',
        add: 'Add Shape',
        manage: 'Manage Shape'
      },
      size: {
        title: 'Size',
        add: 'Add Size',
        manage: 'Manage Size'
      },
      colour: {
        title: 'Colour',
        add: 'Add Colour',
        manage: 'Manage Colour'
      },
      clarity: {
        title: 'Clarity',
        add: 'Add Clarity',
        manage: 'Manage Clarity'
      },
      finish: {
        title: 'Finish',
        add: 'Add Finish',
        manage: 'Manage Finish'
      },
      fluorescence: {
        title: 'Fluorescence',
        add: 'Add Fluorescence',
        manage: 'Manage Fluorescence'
      },
      cut: {
        title: 'Cut',
        add: 'Add Cut',
        manage: 'Manage Cut'
      },
      sizesMarkup: {
        title: 'Sizes Markup',
        add: 'Add Sizes Markup',
        manage: 'Manage Sizes Markup'
      }
    },
    orderManagement: {
      title: 'Order Management',
      orders: {
        title: 'Order',
        add: 'Add Order',
        edit: 'Edit Order',
        view: 'View Order'
      },
      transaction: {
        title: 'Transaction',
        edit: 'Edit Transaction',
        add: 'Add Transaction'
      },
      coupons: {
        title: 'Coupon',
        add: 'Add Coupon',
        edit: 'Edit Coupon'
      }
    },
    customerManagement: {
      title: 'Customer Management',
      organization: {
        title: 'Organization',
        add: 'Add Organization',
        edit: 'Edit Organization'
      },
      contact: {
        title: 'Contact',
        add: 'Add Contact',
        edit: 'Edit Contact'
      },
      address: {
        title: 'Address',
        add: 'Add Address',
        edit: 'Edit Address',
        import: 'Import Address'
      }
    },
    master: {
      title: 'Master',
      tax: {
        title: 'Tax',
        add: 'Add Tax',
        edit: 'Edit Tax',
        manage: 'Manage'
      },
      measurementunits: {
        title: 'Unit Of Measurement',
        add: 'Add Unit Of Measurement',
        edit: 'Edit Unit Of Measurement',
        manage: 'Manage '
      },
      menus: {
        title: 'Menu',
        add: 'Add Menu',
        edit: 'Edit Menu',
        manage: 'Manage '
      },
      subMenus: {
        title: 'Sub Menu',
        add: 'Add Sub Menu',
        edit: 'Edit Sub Menu',
        manage: 'Manage '
      },
      medias: {
        title: 'Media',
        add: 'Add Media',
        edit: 'Edit Media',
        manage: 'Manage '
      },
      homePages: {
        title: 'Home Page',
        add: 'Add Home Page',
        edit: 'Edit Home Page',
        manage: 'Manage '
      },
      homeSections1: {
        title: 'Home Section 1',
        add: 'Add Home Section 1',
        edit: 'Edit Home Section 1',
        manage: 'Manage '
      },
      homeSections2: {
        title: 'Home Section 2',
        add: 'Add Home Section 2',
        edit: 'Edit Home Section 2',
        manage: 'Manage '
      },
      homeSections3: {
        title: 'Home Section 3',
        add: 'Add Home Section 3',
        edit: 'Edit Home Section 3',
        manage: 'Manage '
      },
      trendingOffers: {
        title: 'Trending Offer',
        add: 'Add Trending Offer',
        edit: 'Edit Trending Offer',
        manage: 'Manage '
      },
      collections: {
        title: 'Collections',
        add: 'Add Collection',
        edit: 'Edit Collection',
        manage: 'Manage '
      },
      checkoutSections: {
        title: 'Checkout Section',
        add: 'Add Checkout Section',
        edit: 'Edit Checkout Section',
        manage: 'Manage '
      },
      productsGroups: {
        title: 'Products Group',
        add: 'Add Products Group',
        edit: 'Edit Products Group',
        manage: 'Manage '
      },
      staticPages: {
        title: 'Static Page',
        add: 'Add Static Page',
        edit: 'Edit Static Page',
        manage: 'Manage '
      },
      distributors: {
        title: 'Distributor',
        add: 'Add Distributor',
        edit: 'Edit Distributor',
        manage: 'Manage ',
        import: 'Import Distributor'
      },
      siteMedias: {
        title: 'Site Media',
        add: 'Add Site Media',
        edit: 'Edit Site Media',
        manage: 'Manage '
      }
    },
    blogs: {
      title: 'Blog',
      add: 'Add Blog',
      edit: 'Edit Blog',
      manage: 'Manage '
    },
    locations: {
      title: 'Location Management',
      titleShort: 'Location',
      countries: {
        title: 'Country',
        add: 'Add Country',
        edit: 'Edit Country'
      },
      states: {
        title: 'State',
        add: 'Add State',
        edit: 'Edit State'
      },
      cities: {
        title: 'City',
        add: 'Add City',
        edit: 'Edit City'
      },
      pincode: {
        title: 'Pincode',
        add: 'Add Pincode',
        edit: 'Edit Pincode',
        manage: 'Manage Pincode',
        import: 'Import Pincode'
      }
    },
    notifications: {
      title: 'Notifications',
      manage: 'Manage Notification',
      view: 'View Notification'
    },
    faqs: {
      title: 'FAQs',
      add: 'Add FAQs',
      edit: 'Edit FAQs'
    },
    contacts: {
      title: 'Contact Us',
      add: 'Add Contact Us',
      edit: 'Edit Contact Us'
    },
    newsletters: {
      title: 'Newsletters',
      add: 'Add Newsletter',
      edit: 'Edit Newsletter'
    },
    industryManagement: {
      title: 'Industry Management',
      industry: {
        title: 'Industry',
        add: 'Add Industry',
        edit: 'Edit Industry'
      }
    },
    driverManagement: {
      title: 'Driver Management',
      driver: {
        title: 'Driver',
        add: 'Add Driver',
        edit: 'Edit Driver',
        manage: 'Manage Driver'
      },
      vehicle: {
        title: 'Vehicles',
        add: 'Add Vehicle',
        edit: 'Edit Vehicle',
        manage: 'Manage Vehicle'
      }
    },
    sellerManagement: {
      title: 'Seller Management',
      seller: {
        title: 'Seller',
        add: 'Add Seller',
        manage: 'Manage Seller'
      },
      sellersUsersRole: {
        title: 'Sellers Users Role',
        add: 'Add Sellers Users Role',
        manage: 'Manage Sellers Users Role'
      },
      sellersUser: {
        title: 'Sellers User',
        add: 'Add Sellers User',
        manage: 'Manage Sellers User'
      }
    }
  },
  navbar: {
    logOut: 'Log Out',
    dashboard: 'Dashboard',
    theme: 'Theme',
    size: 'Global Size',
    profile: 'Profile',
    changePassword: 'Change Password'
  },
  login: {
    title: 'Seller Login Form',
    logIn: 'Login',
    mobileNumber: 'mobileNumber',
    email: 'Email',
    password: 'Password',
    otp: 'OTP',
    confirmPassword: 'Confirm Password',
    any: 'any',
    recover: 'Send Recovery Mail',
    recoverTitle: ' Password recovery',
    thirdparty: 'Or connect with',
    thirdpartyTips: 'Can not be simulated on local, so please combine you own business simulation! ! !'
  },
  documentation: {
    documentation: 'Documentation'
  },
  permission: {
    createRole: 'New Role',
    editPermission: 'Edit',
    roles: 'Your roles',
    switchRoles: 'Switch roles',
    tips: 'In some cases, using v-permission will have no effect. For example: Element-UI  el-tab or el-table-column and other scenes that dynamically render dom. You can only do this with v-if.',
    delete: 'Delete',
    confirm: 'Confirm',
    cancel: 'Cancel'
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for the first time. You can briefly introduce the features of the project. Demo is based on ',
    button: 'Show Guide'
  },
  components: {
    documentation: 'Documentation',
    tinymceTips: 'Rich text is a core feature of the management backend, but at the same time it is a place with lots of pits. In the process of selecting rich texts, I also took a lot of detours. The common rich texts on the market have been basically used, and I finally chose Tinymce. See the more detailed rich text comparison and introduction.',
    stickyTips: 'when the page is scrolled to the preset position will be sticky on the top.',
    backToTopTips1: 'When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner',
    backToTopTips2: 'You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally',
    imageUploadTips: 'Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.'
  },
  table: {
    dynamicTips1: 'Fixed header, sorted by header order',
    dynamicTips2: 'Not fixed header, sorted by click order',
    dragTips1: 'The default order',
    dragTips2: 'The after dragging order',
    title: 'Title',
    importance: 'Importance',
    type: 'Type',
    remark: 'Remark',
    search: 'Search',
    add: 'Add',
    export: 'Export',
    import: 'Import',
    reviewer: 'Reviewer',
    id: 'ID',
    date: 'Date',
    author: 'Author',
    readings: 'Readings',
    status: 'Status',
    actions: 'Actions',
    edit: 'Edit',
    publish: 'Publish',
    draft: 'Draft',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    createdDate: 'Created',
    deleteConfirmation: 'Do you want to delete, {name}?',
    deleteCanceled: 'Delete canceled',
    sort: {
      id: {
        asc: 'ID Ascending',
        desc: 'ID Descending'
      },
      enable: {
        enabled: 'Enabled',
        disabled: 'Disabled'
      }
    }
  },
  example: {
    warning: "Creating and editing pages cannot be cached by keep-alive because keep-alive's 'include' currently does not support caching based on routes, so now it's cached based on component name. If you want to achieve a similar caching effect, you can use a browser caching scheme such as localStorage. Or don't use keep-alive's 'include' to cache all pages directly. See details"
  },
  errorLog: {
    tips: 'Please click the bug icon in the upper right corner',
    description: 'Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.',
    documentation: 'Document introduction'
  },
  excel: {
    export: 'Export',
    selectedExport: 'Export Selected Items',
    placeholder: 'Please enter the file name (default excel-list)'
  },
  zip: {
    export: 'Export',
    placeholder: 'Please enter the file name (default file)'
  },
  pdf: {
    tips: 'Here we use window.print() to implement the feature of downloading PDF.'
  },
  theme: {
    change: 'Change Theme',
    documentation: 'Theme documentation',
    tips: 'Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.'
  },
  tagsView: {
    refresh: 'Refresh',
    close: 'Close',
    closeOthers: 'Close Others',
    closeAll: 'Close All'
  },
  settings: {
    title: 'Page style setting',
    theme: 'Theme Color',
    showTagsView: 'Open Tags-View',
    showSidebarLogo: 'Sidebar Logo',
    fixedHeader: 'Fixed Header',
    sidebarTextTheme: 'Sidebar Text Theme'
  },
  admin: {
    fullName: 'Full Name',
    email: 'Email',
    contactNumber: 'Contact Number',
    enabled: 'Enabled',
    role: {
      name: 'Name',
      enabled: 'Enabled'
    }
  },
  location: {
    country: {
      name: 'Name',
      code: 'Code',
      enabled: 'Enabled'
    },
    state: {
      name: 'Name',
      code: 'Code',
      enabled: 'Enabled',
      country: 'Country'
    },
    city: {
      name: 'Name',
      code: 'Code',
      enabled: 'Enabled',
      country: 'Country',
      state: 'State'
    }
  },
  geolocation: {
    prompt: {
      title: 'Geolocation Permission',
      message: 'Kindly allow us to access your current location',
      nonCompatibleBrowser: 'You are using non compatible browser'
    }
  },
  master: {
    title: 'Master',
    tax: {
      name: 'Tax',
      enabled: 'Enabled'
    }
  },
  address: {
    name: 'Name',
    enabled: 'Enabled'
  },
  attribute: {
    name: 'Attribute',
    enabled: 'Enabled'
  },
  attributeValue: {
    name: 'Attribute Value',
    enabled: 'Enabled'
  },
  brand: {
    name: 'Brand',
    enabled: 'Enabled'
  },
  category: {
    name: 'Category',
    enabled: 'Enabled'
  },
  contact: {
    name: 'Contact',
    enabled: 'Enabled'
  },
  order: {
    name: 'Order Reference',
    enabled: 'Enabled',
    userName: 'User Name',
    mobile: 'Mobile Number',
    email: 'Email Address'
  },
  measurementunits: {
    name: 'Unit Of Measurement',
    enabled: 'Enabled'
  },
  organization: {
    name: 'Organization',
    enabled: 'Enabled'
  },
  pincode: {
    name: 'Name',
    code: 'Pincode',
    enabled: 'Enabled'
  },
  coupon: {
    enabled: 'Enabled',
    name: 'Name',
    code: 'Code'
  },
  user: {
    enabled: 'Enabled',
    fullName: 'Full Name',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    mobileNumber: 'Mobile Number'
  },
  product: {
    enabled: 'Enabled',
    stoneId: 'Stone ID',
    category: 'Category',
    attribute: 'Attribute',
    sku: 'SKU',
    carat: 'Carat'
  },
  industry: {
    name: 'Industry',
    enabled: 'Enabled'
  },
  driver: {
    enabled: 'Enabled',
    fullName: 'Full Name',
    email: 'Email',
    mobileNumber: 'Mobile Number'
  },
  vehicle: {
    name: 'Vehicle',
    enabled: 'Enabled'
  },
  menus: {
    name: 'Menu',
    enabled: 'Enabled'
  },
  subMenus: {
    name: 'Sub Menu',
    enabled: 'Enabled'
  },
  medias: {
    name: 'Media',
    enabled: 'Enabled'
  },
  homePages: {
    name: 'Home Page',
    enabled: 'Enabled'
  },
  homeSections1: {
    name: 'Home Section 1',
    enabled: 'Enabled'
  },
  homeSections2: {
    name: 'Home Section 2',
    enabled: 'Enabled'
  },
  homeSections3: {
    name: 'Home Section 3',
    enabled: 'Enabled'
  },
  trendingOffers: {
    name: 'Trending Offer',
    enabled: 'Enabled'
  },
  collections: {
    name: 'Collection',
    enabled: 'Enabled'
  },
  checkoutSections: {
    name: 'Checkout Section',
    enabled: 'Enabled'
  },
  productsGroups: {
    name: 'Products Group',
    enabled: 'Enabled'
  },
  staticPages: {
    name: 'Static Page',
    enabled: 'Enabled'
  },
  distributors: {
    name: 'Distributor',
    enabled: 'Enabled'
  },
  siteMedias: {
    name: 'Static Page',
    enabled: 'Enabled'
  },
  blogs: {
    name: 'Blog',
    enabled: 'Enabled'
  },
  global: {
    form: {
      save: 'Save',
      add: 'Add',
      edit: 'Edit',
      delete: 'Delete',
      reset: 'Reset'
    },
    common: {
      ok: 'Ok',
      error: 'Error'
    }
  },
  notifications: {
    enabled: 'Enabled',
    title: 'Title',
    message: 'Sub Title',
    state: 'State',
    city: 'City',
    send: 'Send'
  }
};