"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _seller = require("@/store/modules/seller");
var _qs = _interopRequireDefault(require("qs"));
const service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000
  // withCredentials: true // send cookies when cross-domain requests
});
// Request interceptors
service.interceptors.request.use(config => {
  // Add X-Access-Token header to every request, you can add other custom headers here
  if (_seller.SellerModule.token) {
    config.headers.Authorization = 'Bearer ' + _seller.SellerModule.token;
  }
  // if type get then serialize params
  if (config.method === 'get') {
    // check if filter exist
    if (config.params && config.params.filter && config.params.filter.length > 0) {
      config.paramsSerializer = function (params) {
        return _qs.default.stringify(params, {
          arrayFormat: 'repeat'
        });
      };
    }
  } else if (config.method === 'patch') {
    config.data.updatedBy = _seller.SellerModule.id;
  } else if (config.method === 'post' && config.data) {
    config.data.createdBy = _seller.SellerModule.id;
  }
  return config;
}, error => {
  Promise.reject(error);
});
// Response interceptors
service.interceptors.response.use(response => {
  return response.data;
}, error => {
  if (!error.response) {
    // network error
    console.log(JSON.stringify(error));
    const errorMessage = 'Network error!';
    (0, _elementUi.Message)({
      message: errorMessage,
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(errorMessage);
  } else if (error.response) {
    if (error.response.data.message) {
      (0, _elementUi.Message)({
        message: error.response.data.message,
        type: 'error',
        duration: 5 * 1000
      });
    }
  }
  return Promise.reject(error.response.data);
});
var _default = exports.default = service;