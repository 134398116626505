"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'guide': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M1.5 70.1l36.2 16.2 70-65.5-61.5 70.6 46.5 18.8c1 .4 2.4-.2 2.8-1.4L128 0 1.3 66c-1.1.6-1.5 2-1 3.1.3.5.7 1 1.2 1.1zM46.2 128l18-20.7-18-7.9V128z"/>'
  }
});