"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  created() {
    const {
      params,
      query
    } = this.$route;
    const {
      path
    } = params;
    this.$router.replace({
      path: '/' + path,
      query
    });
  }
  render() {
    // Avoid warning for missing template
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Redirect'
})], default_1);
var _default = exports.default = default_1;