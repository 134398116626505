"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _products = require("@/api/products");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _seller = require("@/store/modules/seller");
var _index4 = _interopRequireDefault(require("@/components/Inr/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.showCreatedDate = false;
    this.category = '';
    this.attribute = '';
    this.priceNotEntered = false;
    // private dateRange:any = '';
    // private startDate:any = '';
    // private endDate:any = '';
    this.listQuery = {
      page: 1,
      limit: 10,
      sellerId: _seller.SellerModule.sellerId,
      stoneId: undefined,
      enabled: true,
      isOfflineSold: undefined,
      orderStatus: undefined,
      sort: 'id,DESC',
      caratWeight: undefined,
      shapeId: undefined,
      colourId: undefined,
      clarityId: undefined,
      finishId: undefined,
      fluorescenceId: undefined,
      // unitPrice: undefined,
      type: undefined,
      filter: {
        sellerId: 'eq',
        stoneId: '$contL',
        enabled: 'eq',
        isOfflineSold: 'eq',
        orderStatus: 'eq',
        caratWeight: 'eq',
        shapeId: 'eq',
        colourId: 'eq',
        clarityId: 'eq',
        finishId: 'eq',
        fluorescenceId: 'eq',
        // unitPrice: "eq",
        type: 'eq'
      }
    };
    this.value = [];
    this.options = [{
      value: 'createdDate',
      label: 'Created Date'
    }];
    this.columns = {
      createdDate: false
    };
    this.sortOptions = [{
      label: 'Added',
      options: [{
        label: 'Recently',
        key: 'id,DESC'
      }, {
        label: 'Previously',
        key: 'id,ASC'
      }]
    }, {
      label: 'Price',
      options: [{
        label: 'High',
        key: 'productsVariation.unitPrice,DESC',
        icon: 'el-icon-bottom'
      }, {
        label: 'Low',
        key: 'productsVariation.unitPrice,ASC',
        icon: 'el-icon-top'
      }]
    }, {
      label: 'Carat',
      options: [{
        label: 'High',
        key: 'caratWeight,DESC',
        icon: 'el-icon-arrow-up'
      }, {
        label: 'Low',
        key: 'caratWeight,ASC',
        icon: 'el-icon-arrow-down'
      }]
    }, {
      label: 'Stone ID',
      options: [{
        label: 'A-Z',
        key: 'stoneId,ASC',
        icon: 'el-icon-sort-up'
      }, {
        label: 'Z-A',
        key: 'stoneId,DESC',
        icon: 'el-icon-sort-down'
      }]
    }, {
      label: 'Availability',
      options: [{
        label: 'Available',
        key: 'isOfflineSold,ASC',
        icon: 'el-icon-check'
      }, {
        label: 'Sold Offline',
        key: 'isOfflineSold,DESC',
        icon: 'el-icon-close'
      }]
    }, {
      label: 'Type',
      options: [{
        label: 'Pointer',
        key: 'type,ASC',
        icon: 'el-icon-caret-top'
      }, {
        label: 'Parcel',
        key: 'type,DESC',
        icon: 'el-icon-caret-bottom'
      }]
    }];
    this.filterOptions = [{
      key: 'isOfflineSold',
      placeholder: 'Sold Offline',
      style: {
        width: '120px'
      },
      items: [{
        id: true,
        name: 'Yes'
      }, {
        id: false,
        name: 'No'
      }]
    }, {
      key: 'orderStatus',
      placeholder: 'Sold Online',
      style: {
        width: '120px'
      },
      items: [{
        id: 'Available',
        name: 'Available'
      }, {
        id: 'Sold Online',
        name: 'Sold Online'
      }]
    }, {
      key: 'type',
      placeholder: 'Product Type',
      style: {
        width: '150px'
      },
      items: [{
        id: 'Pointer',
        name: 'Pointer'
      }, {
        id: 'Parcel',
        name: 'Parcel'
      }]
    }, {
      key: 'shapeId',
      placeholder: 'Shape',
      style: {
        width: '120px'
      },
      items: [],
      url: 'shapes'
    }, {
      url: 'colours',
      key: 'colourId',
      placeholder: 'Colour',
      style: {
        width: '120px'
      },
      items: []
    }, {
      key: 'clarityId',
      placeholder: 'Clarity',
      style: {
        width: '120px'
      },
      items: [],
      url: 'clarities'
    }, {
      key: 'finishId',
      placeholder: 'Finish',
      style: {
        width: '120px'
      },
      items: [],
      url: 'finishes'
    }, {
      url: 'fluorescences',
      key: 'fluorescenceId',
      placeholder: 'Fluorescence',
      style: {
        width: '150px'
      },
      items: []
    }];
    this.selectedProducts = [];
    this._priceEditingFlag = false;
  }
  created() {
    if (this.$route.query && this.$route.query.price && this.$route.query.price == '0') {
      this.priceNotEntered = true;
    }
    this.populateProductFilterOptions();
    this.doURLQuery();
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      if (this.priceNotEntered) {
        this.listQuery['productsVariation.unitPrice'] = 0;
        this.listQuery.filter['productsVariation.unitPrice'] = '$eq';
      }
      // check if isOfflineSold is selected as orderStatus
      if (this.listQuery.orderStatus) {
        // making isOfflineSold as false as its not sold yet
        this.listQuery.isOfflineSold = false;
      }
      const data = await (0, _products.getProducts)((0, _index2.getQuery)(this.listQuery));
      // add random lifetimeOrderValue & totalOrders
      this.list = data.data.map(product => {
        product.priceEditing = false;
        product.soldOfflineEditing = false;
        return product;
      });
      // this.list = data.data
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter(event = null) {
    this.listQuery.page = 1;
    this.getList();
  }
  handleColumn() {
    for (const c in this.columns) {
      this.columns[c] = this.value.includes(c);
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === 'ascending' ? 'ASC' : 'DESC');
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? 'ascending' : sort === `${key},DESC` ? 'descending' : '';
  }
  async handleDownload() {
    this.downloadLoading = true;
    const tHeader = ['Product Id', 'Product Name', 'Product Type', 'Brand', 'Featured', 'Unit of Measurement', 'Sub Category', 'Attributes & Values', 'SKU', 'Tax', 'MRP', 'Sale Price', 'Basic Price', 'Manage Stock', 'Stock Status', 'Stock Quantity', 'Allow backorders', 'Low Stock Threshold', 'HSN Code', 'Min Order Quantity', 'Max Order Quantity', 'Priority', 'Short Description', 'Tags'];
    const subHeader = ['numeric', 'mandatory', 'mandatory', 'optional', 'visible on homepage', 'use code (eg kg,gm)', 'multiple comma seperated', 'only if variable product type is selected \nAttributes & Values separated by ~ \nmultiple separated by , (comma)', 'mandatory', 'optional \nenter percentage value', 'mandatory', 'optional', 'Yes - Y No - N or leave empty', 'Y: System will manage based on available quantity', 'enter only if Manage Stock is N', 'enter only if Manage Stock is Y', 'enter only if Manage Stock is Y \ncan allow order more than available quantity', 'enter only if Manage Stock is Y \nsystem will send notification if quantity goes below this value', 'optional \nenter only if Tax is entered', '- leave blank for 1 - default 1', '- leave blank for no max value - default unlimited', 'numeric', 'optional', 'optional \nmultiple separated by , (comma) \nhelps in search'];
    const filterVal = ['id', 'name', 'productType', 'brand.name', 'featured', 'measurementUnit.code', 'category', 'attribute.name', 'sku', 'tax.rate', 'regularPrice', 'salePrice', 'stockStatus', 'stockQuantity', 'allowBackOrder', 'lowStockThreshold', 'hsnCode', 'minOrderQuantity', 'maxOrderQuantity', 'priority', 'shortDescription', 'tags'
    // "bulk1",
    // "bulk2",
    // "bulk3",
    ];
    const productFilter = Object.assign({}, this.listQuery);
    delete productFilter.limit;
    delete productFilter.page;
    const data2 = await (0, _products.exportProducts)();
    const data = (0, _index2.formatJsonProduct)(filterVal, data2);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc('route.productManagement.product.title'), [subHeader]);
    this.downloadLoading = false;
  }
  async handleExport() {
    try {
      this.downloadLoading = true;
      const tHeader = ['Stone Id', 'Parcel Weight', 'Diamond Pieces', 'Short Description', 'Price', 'Sold Offline', 'Product Type', 'Carat', 'Shape', 'Colour', 'Clarity', 'Finish', 'Fluorescence'];
      const filterVal = ['stoneId', 'parcelWeight', 'diamondPieces', 'shortDescription', 'productsVariation.0.unitPrice', 'isOfflineSold', 'type', 'caratWeight', 'shape.name', 'colour.name', 'clarity.name', 'finish.name', 'fluorescence.name'];
      const data = (0, _index2.formatJson)(filterVal, this.selectedProducts);
      console.log(data);
      (0, _excel.exportJson2Excel)(tHeader, data, 'Product Export');
    } catch (error) {
      this.$message({
        type: 'warning',
        message: error.toString()
      });
    } finally {
      this.downloadLoading = false;
    }
  }
  isSuperAdmin() {
    return _seller.SellerModule.roles.indexOf('superadmin') > -1;
  }
  loadImage(image) {
    if (image.indexOf('-sm') !== -1) {
      image = image.replace('-sm', '-md');
    }
    // image = image + '-md'
    return image;
  }
  getRandomOrderValue(min = 10, max = 10000, toFixed = 2) {
    return Math.floor(Math.random() * (max - min + 1) + min).toFixed(toFixed);
  }
  editPrice(index) {
    // mark priceEditing of each list as false
    this.list.forEach(item => {
      item.priceEditing = false;
    });
    // update as initial
    this.list[index].productsVariation[0].unitPriceToBeUpdated = this.list[index].productsVariation[0].unitPrice;
    // show
    this.list[index].priceEditing = true;
    // global edit flag
    this._priceEditingFlag = true;
  }
  closePriceEditing(index) {
    this.list[index].priceEditing = false;
    this._priceEditingFlag = false;
  }
  editSoldOffline(row) {
    row.soldOfflineEditing = true;
  }
  closeSoldOffline(index) {
    this.list[index].soldOfflineEditing = false;
  }
  async savePrice(index) {
    try {
      this.list[index].savingPrice = true;
      this.list[index].productsVariation[0] = await (0, _products.updateProductPrice)({
        id: this.list[index].productsVariation[0].id,
        stoneId: this.list[index].stoneId,
        unitPrice: this.list[index].productsVariation[0].unitPriceToBeUpdated
      });
      this.closePriceEditing(index);
      this.$message({
        message: 'Price updated!',
        type: 'success'
      });
    } catch (error) {
      this.$message({
        message: error,
        type: 'error'
      });
    } finally {
      this.list[index].savingPrice = false;
    }
  }
  async saveSoldOffline(index, newValue) {
    try {
      this.list[index].soldOfflineEditing = true;
      const data = {
        isOfflineSold: newValue,
        stoneId: this.list[index].stoneId
      };
      if (newValue === true) {
        data.offlineMarkedByType = 'Seller';
        data.offlineMarkedById = _seller.SellerModule.id;
        data.offlineMarkedByFullName = _seller.SellerModule.firstName + ' ' + _seller.SellerModule.lastName || '';
        data.offlineMarkedTimestamp = new Date().toISOString();
      }
      const product = await (0, _products.updateProduct)(this.list[index].id, data);
      this.list[index].isOfflineSold = product.isOfflineSold;
      this.closeSoldOffline(index);
      this.$message({
        message: 'Status updated!',
        type: 'success'
      });
    } catch (error) {
      this.$message({
        message: error,
        type: 'error'
      });
    } finally {
      this.list[index].soldOfflineEditing = false;
    }
  }
  handleSelectionChange(selectedProducts) {
    this.selectedProducts = selectedProducts;
  }
  populateProductFilterOptions() {
    // loop over
    this.filterOptions.forEach(async filterOption => {
      if (filterOption.url) {
        const response = await (0, _products.productAttributesRequest)(filterOption.url, (0, _index2.getQuery)({
          enabled: true,
          // sort: "priority:DESC", // Mubasshir
          filter: {
            enabled: 'eq'
          }
        }));
        if (response !== null && response !== void 0 && response.data) {
          filterOption.items = response === null || response === void 0 ? void 0 : response.data;
        }
      }
    });
  }
  getStatus(product) {
    if (product) {
      // check if sold offline
      if (product.isOfflineSold) {
        return 'Sold Offline';
      }
      return product.orderStatus;
    }
    return '-';
  }
  handleImport() {
    this.$message({
      message: 'Coming soon!',
      type: 'warning'
    });
  }
  isPriceAvailable(productsVariation = null) {
    if (productsVariation) {
      return +productsVariation.unitPrice > 0;
    }
    return false;
  }
  doURLQuery() {
    const query = this.$route.query;
    if (query) {
      for (const key in query) {
        if (Object.prototype.hasOwnProperty.call(this.listQuery, key)) {
          this.listQuery[key] = query[key];
        }
      }
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductList',
  components: {
    Pagination: _index.default,
    Enabled: _index3.default,
    Inr: _index4.default
  }
})], default_1);
var _default = exports.default = default_1;