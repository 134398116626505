"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("el-form", {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    },
    on: {
      submit: function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("img", {
    staticClass: "login-logo",
    attrs: {
      src: require("@/assets/img/logo.png")
    }
  }), _c("br"), _c("h1", {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.$t("login.title")) + " ")])]), _vm.isGlobal ? _c("el-form-item", {
    attrs: {
      prop: "emailAddress"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "user"
    }
  })], 1), _c("el-input", {
    ref: "emailAddress",
    attrs: {
      placeholder: "Email Address",
      name: "emailAddress",
      type: "text",
      autocomplete: "off",
      "show-word-limit": "",
      disabled: _vm.isEmailFieldDisabled
    },
    on: {
      input: _vm.validateUsername
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleEnterKey.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.emailAddress,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "emailAddress", $$v);
      },
      expression: "loginForm.emailAddress"
    }
  }), _vm.isEmailFieldDisabled ? _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.editEmail
    }
  }, [_c("i", {
    staticClass: "el-icon-edit"
  })]) : _vm._e()], 1) : _c("el-form-item", {
    attrs: {
      prop: "mobileNumber"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "user"
    }
  })], 1), _c("el-input", {
    ref: "mobileNumber",
    attrs: {
      placeholder: "Mobile Number",
      name: "mobileNumber",
      type: "text",
      autocomplete: "off",
      maxlength: "10",
      "show-word-limit": "",
      disabled: _vm.isNumberFieldDisabled
    },
    on: {
      input: _vm.validateMobile
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleEnterKey.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.mobileNumber,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "mobileNumber", $$v);
      },
      expression: "loginForm.mobileNumber"
    }
  }), _vm.isNumberFieldDisabled ? _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.editNumber
    }
  }, [_c("i", {
    staticClass: "el-icon-edit"
  })]) : _vm._e()], 1), _vm.showOtpField ? _c("el-form-item", {
    attrs: {
      prop: "otp"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "password"
    }
  })], 1), _c("el-input", {
    key: _vm.passwordType,
    ref: "otp",
    attrs: {
      type: _vm.passwordType,
      placeholder: "OTP",
      name: "otp",
      "min-length": "4",
      "max-length": "4",
      autocomplete: "off"
    },
    on: {
      input: _vm.handleOTPInput
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleVerifyOtp.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.otp,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "otp", $$v);
      },
      expression: "loginForm.otp"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1) : _vm._e(), _vm.showOtpField ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    staticClass: "full-width",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary",
      icon: "el-icon-switch-button"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleVerifyOtp.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("login.logIn")) + " ")])], 1), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    staticClass: "full-width",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      loading: _vm.loading,
      type: "warning",
      icon: "el-icon-s-promotion",
      disabled: !!_vm.resendTimer
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleResendOtp.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s("Resend OTP") + " ")])], 1)], 1) : _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-button", {
    staticClass: "full-width",
    staticStyle: {
      width: "40%",
      "margin-left": "150px",
      "margin-bottom": "30px"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary",
      icon: "el-icon-s-promotion",
      disabled: _vm.resendCount > 3
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleResendOtp.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s("Send OTP") + " ")])], 1), _vm.resendTimer || _vm.resendCount > 3 ? _c("el-statistic", {
    staticStyle: {
      color: "white !important",
      "font-size": "14px"
    },
    attrs: {
      value: _vm.resendTimer,
      "time-indices": "",
      title: "",
      format: "mm:ss"
    }
  }, [_c("template", {
    staticStyle: {
      color: "white !important",
      "font-size": "14px"
    },
    slot: "prefix"
  }, [_vm._v(" Resend OTP after ")])], 2) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;