"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleClass = exports.removeClass = exports.parseTime = exports.hasClass = exports.getQuery = exports.getDescendantProp = exports.formatTime = exports.formatJsonUser = exports.formatJsonProduct = exports.formatJsonOrder = exports.formatJson = exports.addClass = void 0;
require("core-js/modules/es.array.push.js");
var _moment = _interopRequireDefault(require("moment"));
// Parse the time to string
const parseTime = (time, cFormat) => {
  if (time === undefined) {
    return null;
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;
  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    if (result.length > 0 && value < 10) {
      return '0' + value;
    }
    return String(value) || '0';
  });
  return timeStr;
};
exports.parseTime = parseTime;
const formatJson = (filterKeys, jsonData) => {
  const out = [];
  const c = 0;
  for (const data of jsonData) {
    const dataExport = [];
    for (const key of filterKeys) {
      if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
        // dataExport.push(parseTime(data[key]))
        dataExport.push(formatTime(data[key]));
      } else if (key === 'enabled') {
        dataExport.push(data[key] ? 'enabled' : 'disabled');
      } else if (key.indexOf('.') > 0) {
        dataExport.push(getDescendantProp(data, key));
      } else if (key === 'balance') {
        const diff = data.grandTotal - data.amountReceived;
        if (diff < 0) {
          dataExport.push(0);
        } else {
          dataExport.push(diff);
        }
      } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
        // var products = ''
        // for (var i = 0; i < data.ordersProduct.length; i++) {
        //   if (data.ordersProduct[i].product) {
        // var productName = 'Product: ' + data.ordersProduct[i].product.name + ' SKU: ' + data.ordersProduct[i].productsVariation.sku + ' Quantity: ' + data.ordersProduct[i].quantity;
        //     products += `${data.ordersProduct[i].product.name}`
        //   }
        // }
        dataExport.push('');
      } else if (key === 'address') {
        const addressObj = data.address;
        if (addressObj) {
          const address = [addressObj.line1, addressObj.line2];
          if (addressObj.pincode) {
            address.push(addressObj.pincode.name);
          }
          if (addressObj.city) {
            address.push(addressObj.city.name);
          }
          if (addressObj.state) {
            address.push(addressObj.state.name);
          }
          if (addressObj.country) {
            address.push(addressObj.country.name);
          }
          dataExport.push(address.filter(Boolean).join(',  '));
        } else {
          dataExport.push('');
        }
      } else if (key === 'shippingAddress') {
        const shippingAddressObj = data.shippingAddress;
        if (shippingAddressObj) {
          const shippingAddress = [shippingAddressObj.line1, shippingAddressObj.line2];
          if (shippingAddressObj.pincode) {
            shippingAddress.push(shippingAddressObj.pincode.name);
          }
          if (shippingAddressObj.city) {
            shippingAddress.push(shippingAddressObj.city.name);
          }
          if (shippingAddressObj.state) {
            shippingAddress.push(shippingAddressObj.state.name);
          }
          if (shippingAddressObj.country) {
            shippingAddress.push(shippingAddressObj.country.name);
          }
          dataExport.push(shippingAddress.filter(Boolean).join(',  '));
        } else {
          dataExport.push('');
        }
      } else if (key === 'paymentMethod') {
        dataExport.push('PhonePe');
      } else if (key === 'billToName') {
        dataExport.push(data.address.firstName + ' ' + data.address.lastName);
      } else if (key === 'shipToName') {
        dataExport.push(data.shippingAddress.firstName + ' ' + data.shippingAddress.lastName);
      } else if (key === 'customerName') {
        dataExport.push(data.user.firstName + ' ' + data.user.lastName);
      } else if (key === 'orderTimestamp') {
        dataExport.push(formatTime(data.orderTimestamp));
      } else {
        dataExport.push(data[key]);
      }
    }
    if (filterKeys.includes('products') && 'ordersProduct' in data) {
      for (const prod of data.ordersProduct) {
        const temp = [...dataExport];
        if (prod.product) {
          // temp[13] = prod.product.name
          if (prod.product.productType === 'simple') {
            temp[13] = prod.product.name;
          } else {
            temp[13] = prod.product.name + ' - ' + prod.productsVariation.name;
          }
        }
        temp[14] = prod.productsVariation.sku;
        temp[15] = prod.quantity;
        out.push(temp);
      }
    } else {
      out.push(dataExport);
    }
  }
  return out;
};
exports.formatJson = formatJson;
const formatJsonOrder = (filterKeys, jsonData) => {
  const out = [];
  const c = 0;
  for (const data of jsonData) {
    const dataExport = [];
    for (const key of filterKeys) {
      if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
        dataExport.push(parseTime(data[key]));
      } else if (key === 'enabled') {
        dataExport.push(data[key] ? 'enabled' : 'disabled');
      } else if (key.indexOf('.') > 0) {
        dataExport.push(getDescendantProp(data, key));
      } else if (key === 'balance') {
        const diff = data.grandTotal - data.amountReceived;
        if (diff < 0) {
          dataExport.push(0);
        } else {
          dataExport.push(diff);
        }
      } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
        // var products = ''
        // for (var i = 0; i < data.ordersProduct.length; i++) {
        //   if (data.ordersProduct[i].product) {
        //     products += `${data.ordersProduct[i].product.name}`
        //   }
        // }
        dataExport.push('');
      } else if (key === 'address') {
        const addressObj = data.address;
        if (addressObj) {
          const address = [addressObj.line1, addressObj.line2];
          if (addressObj.pincode) {
            address.push(addressObj.pincode.name);
          }
          if (addressObj.city) {
            address.push(addressObj.city.name);
          }
          if (addressObj.state) {
            address.push(addressObj.state.name);
          }
          if (addressObj.country) {
            address.push(addressObj.country.name);
          }
          dataExport.push(address.filter(Boolean).join(',  '));
        } else {
          dataExport.push('');
        }
      } else if (key === 'paymentMethod') {
        if (data.status !== 'in cart') {
          if (data.paymentMethod === 'online') {
            dataExport.push('PhonePe');
          } else {
            dataExport.push('COD');
          }
        } else {
          dataExport.push('');
        }
      } else if (key === 'orderTimestamp') {
        if (data.status !== 'in cart') {
          dataExport.push(formatTime(data.orderTimestamp));
        } else {
          dataExport.push(formatTime(data.createdTimestamp));
        }
      } else {
        dataExport.push(data[key]);
      }
    }
    if (filterKeys.includes('products') && 'ordersProduct' in data) {
      for (const prod of data.ordersProduct) {
        const temp = [...dataExport];
        if (prod.product) {
          temp[5] = prod.product.name;
          if (prod.product.productType === 'variable') {
            temp[6] = prod.productsVariation.name;
          } else {
            temp[6] = '';
          }
        }
        temp[7] = prod.unitPrice;
        temp[8] = prod.salePrice;
        temp[9] = prod.quantity;
        temp[10] = prod.subTotal;
        out.push(temp);
      }
    } else {
      out.push(dataExport);
    }
  }
  return out;
};
exports.formatJsonOrder = formatJsonOrder;
const formatJsonUser = (filterKeys, jsonData) => {
  const out = [];
  const c = 0;
  for (const data of jsonData) {
    const dataExport = [];
    for (const key of filterKeys) {
      if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
        dataExport.push(parseTime(data[key]));
      } else if (key === 'enabled') {
        dataExport.push(data[key] ? 'enabled' : 'disabled');
      } else if (key.indexOf('.') > 0) {
        dataExport.push(getDescendantProp(data, key));
      } else if (key === 'balance') {
        const diff = data.grandTotal - data.amountReceived;
        if (diff < 0) {
          dataExport.push(0);
        } else {
          dataExport.push(diff);
        }
      } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
        // var products = ''
        // for (var i = 0; i < data.ordersProduct.length; i++) {
        //   if (data.ordersProduct[i].product) {
        //     products += `${data.ordersProduct[i].product.name}`
        //   }
        // }
        dataExport.push('');
      } else if (key === 'address') {
        let addressObj = data.address;
        if (addressObj && addressObj.length) {
          addressObj = addressObj[0];
          const address = [addressObj.line1, addressObj.line2];
          if (addressObj.pincode) {
            address.push(addressObj.pincode.name);
          }
          if (addressObj.city) {
            address.push(addressObj.city.name);
          }
          if (addressObj.state) {
            address.push(addressObj.state.name);
          }
          if (addressObj.country) {
            address.push(addressObj.country.name);
          }
          dataExport.push(address.filter(Boolean).join(',  '));
        } else {
          dataExport.push('');
        }
      } else if (key === 'paymentMethod') {
        if (data.status !== 'in cart') {
          if (data.paymentMethod === 'online') {
            dataExport.push('PhonePe');
          } else {
            dataExport.push('COD');
          }
        } else {
          dataExport.push('');
        }
      } else if (key === 'registeredOn') {
        dataExport.push(formatTime(data.createdTimestamp));
      } else {
        dataExport.push(data[key]);
      }
    }
    if (filterKeys.includes('products') && 'ordersProduct' in data) {
      for (const prod of data.ordersProduct) {
        const temp = [...dataExport];
        if (prod.product) {
          temp[3] = prod.product.name;
        }
        temp[4] = '';
        temp[5] = prod.unitPrice;
        temp[6] = prod.salePrice;
        temp[7] = prod.quantity;
        temp[8] = prod.subTotal;
        out.push(temp);
      }
    } else {
      out.push(dataExport);
    }
  }
  return out;
};
exports.formatJsonUser = formatJsonUser;
const formatJsonProduct = (filterKeys, jsonData) => {
  const out = [];
  const c = 0;
  for (const data of jsonData) {
    const dataExport = [];
    for (const key of filterKeys) {
      if (['createdTimestamp', 'timestamp'].indexOf(key) > -1) {
        dataExport.push(parseTime(data[key]));
      } else if (['enabled', 'featured', 'manageStock'].includes(key)) {
        dataExport.push(data[key] ? 'Y' : 'N');
      } else if (key.indexOf('.') > 0) {
        dataExport.push(getDescendantProp(data, key));
      } else if (key === 'balance') {
        const diff = data.grandTotal - data.amountReceived;
        if (diff < 0) {
          dataExport.push(0);
        } else {
          dataExport.push(diff);
        }
      } else if (key === 'products' || key === 'quantity' || key === 'totalAmount' || key === 'taxAmount' || key === 'rate') {
        // var products = ''
        // for (var i = 0; i < data.ordersProduct.length; i++) {
        //   if (data.ordersProduct[i].product) {
        //     products += `${data.ordersProduct[i].product.name}`
        //   }
        // }
        dataExport.push('');
      } else if (key === 'category') {
        const categories = [];
        for (const value of data.category) {
          categories.push(value.name);
        }
        dataExport.push(categories.toString());
      } else if (key === 'productsImage') {
        const images = [];
        for (const value of data.productsImage) {
          images.push(value.image);
        }
        dataExport.push(images.toString());
      } else {
        dataExport.push(data[key]);
      }
    }
    if (filterKeys.includes('products') && 'ordersProduct' in data) {
      for (const prod of data.ordersProduct) {
        var temp = [...dataExport];
        if (prod.product) {
          temp[6] = prod.product.name;
        }
        temp[7] = prod.quantity;
        temp[8] = prod.unitPrice;
        temp[9] = prod.subTotal;
        temp[10] = prod.taxedAmount;
        out.push(temp);
      }
    } else if (filterKeys.includes('sku')) {
      for (const variation of data.productsVariation) {
        var temp = [...dataExport];
        if (data.productType === 'variable' && variation.productsVariationsAttribute) {
          const attributeValues = [];
          for (const item of variation.productsVariationsAttribute) {
            attributeValues.push(item.attribute.name + '~' + item.attributesValue.name);
          }
          if (attributeValues.length) {
            temp[8] = attributeValues.toString();
          }
        } else {
          temp[8] = '';
        }
        temp[9] = variation.sku;
        temp[11] = variation.unitPrice;
        temp[12] = variation.salePrice;
        temp[15] = JSON.parse(variation.allowBackOrder) ? 'Y' : 'N';
        temp[16] = variation.stockStatus === 'In Stock' ? 'Y' : 'N';
        temp[17] = variation.stockQuantity;
        temp[18] = JSON.parse(variation.allowBackOrder) ? 'Y' : 'N';
        temp[19] = variation.lowStockThreshold;
        out.push(temp);
      }
    } else {
      out.push(dataExport);
    }
  }
  return out;
};
exports.formatJsonProduct = formatJsonProduct;
const formatTime = time => {
  return (0, _moment.default)(time).utc(false).local().format('LLLL');
};
// Check if an element has a class
exports.formatTime = formatTime;
const hasClass = (ele, className) => {
  return !!ele.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};
// Add class to element
exports.hasClass = hasClass;
const addClass = (ele, className) => {
  if (!hasClass(ele, className)) ele.className += ' ' + className;
};
// Remove class from element
exports.addClass = addClass;
const removeClass = (ele, className) => {
  if (hasClass(ele, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
};
// Toggle class for the selected element
exports.removeClass = removeClass;
const toggleClass = (ele, className) => {
  if (!ele || !className) {
    return;
  }
  let classString = ele.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += '' + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }
  ele.className = classString;
};
exports.toggleClass = toggleClass;
const getQuery = query => {
  const processedQuery = Object.assign({}, query);
  // set filter as empty
  processedQuery.filter = [];
  // check for filter
  if ('filter' in query) {
    // loop over fields and check for values
    Object.entries(query.filter).forEach(([field, condition]) => {
      // check if field value has be set
      if (field in query && [undefined, ''].indexOf(query[field]) === -1) {
        processedQuery.filter.push(field + '||' + condition + '||' + processedQuery[field]);
        delete processedQuery[field];
      }
    });
  }
  return processedQuery;
};
exports.getQuery = getQuery;
const getDescendantProp = (obj, desc) => {
  const arr = desc.split('.');
  while (arr.length && (obj = obj[arr.shift()]));
  return obj;
};
exports.getDescendantProp = getDescendantProp;