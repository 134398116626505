"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("el-form", {
    ref: "productGenFormData",
    staticClass: "demo-form",
    attrs: {
      model: _vm.productGenFormData,
      "status-icon": "",
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Stone Id",
      prop: "stoneId"
    }
  }, [_c("el-input-number", {
    attrs: {
      name: "stoneId",
      placeholder: "stoneId",
      controls: false,
      disabled: ""
    },
    model: {
      value: _vm.productGenFormData.stoneId,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "stoneId", $$v);
      },
      expression: "productGenFormData.stoneId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "Offline Sold",
      prop: "offlineSold"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.productGenFormData.isOfflineSold,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData, "isOfflineSold", $$v);
      },
      expression: "productGenFormData.isOfflineSold"
    }
  })], 1), _vm.productGenFormData.productType === "simple" && _vm.productGenFormData.productsVariation.length ? [_c("el-form-item", {
    attrs: {
      label: "Price (₹)"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "sub-title"
  }, [_vm._v(" Regular Price ")]), _c("el-input-number", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      name: "unitPrice",
      placeholder: "MRP",
      size: "mini",
      min: 0,
      precision: 2,
      controls: false
    },
    model: {
      value: _vm.productGenFormData.productsVariation[0].unitPrice,
      callback: function ($$v) {
        _vm.$set(_vm.productGenFormData.productsVariation[0], "unitPrice", $$v);
      },
      expression: "productGenFormData.productsVariation[0].unitPrice"
    }
  }), _c("help-text", {
    attrs: {
      content: ""
    }
  })], 1)], 1)], 1)] : _vm._e(), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;