"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadFile = exports.toggleEnable = exports.getAppVersions = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const toggleEnable = (url, data) => (0, _request.default)({
  url: url,
  method: 'patch',
  data
});
exports.toggleEnable = toggleEnable;
const uploadFile = (data, url = '/common/upload', onUploadProgressCallBack) => (0, _request.default)({
  url: url,
  method: 'post',
  data,
  onUploadProgress: onUploadProgressCallBack
});
exports.uploadFile = uploadFile;
const getAppVersions = () => (0, _request.default)({
  url: '/common/app_version',
  method: 'get'
});
exports.getAppVersions = getAppVersions;