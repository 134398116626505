"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'dollar': {
    width: 800,
    height: 800,
    viewBox: '0 0 235.517 235.517',
    data: '<path pid="0" d="M118.1 235.517c7.898 0 14.31-6.032 14.31-13.483v-13.473c39.069-3.579 64.932-24.215 64.932-57.785v-.549c0-34.119-22.012-49.8-65.758-59.977V58.334a114.786 114.786 0 0119.194 6.549c10.258 4.547 22.724 1.697 28.952-8.485 6.233-10.176 2.866-24.47-8.681-29.654-11.498-5.156-24.117-8.708-38.095-10.236V8.251c0-4.552-6.402-8.251-14.305-8.251s-14.31 3.514-14.31 7.832v7.843c-42.104 3.03-65.764 25.591-65.764 58.057v.555c0 34.114 22.561 49.256 66.862 59.427v33.021c-10.628-1.713-21.033-5.243-31.623-10.65-11.281-5.755-25.101-3.72-31.938 6.385-6.842 10.1-4.079 24.449 7.294 30.029 16.709 8.208 35.593 13.57 54.614 15.518v13.755c.006 7.588 6.413 13.745 14.316 13.745zm13.201-97.397c14.316 4.123 18.438 8.257 18.438 15.681v.555c0 7.979-5.776 12.651-18.438 14.033V138.12zM86.999 70.153v-.549c0-7.152 5.232-12.657 18.71-13.755v29.719c-14.853-4.129-18.71-8.263-18.71-15.415z" _fill="#010002"/>'
  }
});