"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'documentation': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M72 44.8h43.9l-44-35.2v35.2zM16 0h64l47.9 38.4v76.8c0 3.4-1.7 6.6-4.7 9-3 2.4-7 3.7-11.3 3.7H16.1c-4.2 0-8.3-1.3-11.3-3.7-3-2.4-4.7-5.6-4.7-9V12.8C.1 5.8 7.2 0 16.1 0zm72 102.4V89.6H16v12.8h72zm24-25.6V64H16v12.8h96z"/>'
  }
});