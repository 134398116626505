"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'finish': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<g _fill="#0a3378"><path pid="0" d="M359.2 201.9l-38.6-37.1c-1.6-1.4-3.4-2.5-5.4-3.2l-43.4-15.2c-1.7-.6-3.5-.9-5.3-.9h-90c-1.8 0-3.6.3-5.3.9l-43.4 15.2c-2.1.7-3.9 1.9-5.6 3.3l-38.3 36.8c-4.5 4-5.2 11-1.6 15.9l129.6 171.8c4.1 5.5 11.7 6.6 17 2.3.8-.6 1.5-1.4 2.2-2.2l129.7-171.9c3.5-4.8 2.8-11.7-1.6-15.7zm-183.7-41.8c.3-.1.6-.2.9-.2h90c.3 0 .6.1.9.2l28.7 10.1-35.8 12.4H186L147.3 170l28.2-9.9zM268 233h-82.4l5.2-36.1h67.4L268 233zm-139.9-54.1l49 15.9-5.3 37.2-71.4-26.5 27.7-26.6zm-25.2 42.8s68.7 25.4 68.8 25.5l33.9 110.6-102.7-136.1zm119.3 143.1l-35.9-117.3h80.8l-44.9 117.3zm20-13.3l40.8-107 56.6-22.3-97.4 129.3zm39.3-121.8l-9.8-35.9 43.2-14.9 27.8 26.7-61.2 24.1zM214.6 104.1v20c0 4 3.1 7.2 6.9 7.2s6.9-3.2 6.9-7.2v-20c0-4-3.1-7.2-6.9-7.2s-6.9 3.2-6.9 7.2zM181.8 114.5c-2.5 3-2.3 7.5.6 10.2l6.9 6.4c2.9 2.5 7.2 2.3 9.7-.6 2.5-3 2.3-7.5-.6-10.2l-6.9-6.4c-2.8-2.6-7.2-2.4-9.7.6zM244.5 120.3c-2.9 2.6-3.1 7.2-.6 10.2 2.6 2.9 6.9 3.1 9.7.6l6.9-6.4c2.9-2.6 3.1-7.2.6-10.2-2.5-3-6.9-3.2-9.7-.6l-6.9 6.4zM369.9 285.3c-34.3 0-62.1 29.1-62.1 64.9s27.8 64.9 62.1 64.9S432 386 432 350.2c0-35.8-27.8-64.9-62.1-64.9zm0 115.4c-26.7 0-48.3-22.6-48.3-50.5s21.6-50.5 48.3-50.5c26.7 0 48.3 22.6 48.3 50.5s-21.6 50.5-48.3 50.5z"/><path pid="1" d="M390.2 337l-25.1 18-7.1-9c-2.4-3.1-6.8-3.5-9.7-1-2.9 2.5-3.4 7.1-1 10.2l11.1 14.1c2.3 2.9 6.3 3.5 9.2 1.4L398 349c3.1-2.3 3.9-6.7 1.8-10-2.2-3.3-6.4-4.2-9.6-2z"/></g><path pid="2" d="M256 5C117.7 5 5.5 117.4 5.5 256S117.7 507 256 507s250.5-112.4 250.5-251C506.3 117.4 394.3 5.2 256 5zm3.9 487.3c-121.5 1.9-223.3-93-235.8-208.8C9.7 150.5 116.6 24 254 22.6c129.2-1.3 238.2 108.5 235.8 239.2-2.2 124.6-104.8 228.5-229.9 230.5z" _fill="#0a3378"/>'
  }
});