"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.refreshing = false;
    this.notificationText = 'New content is available!';
    this.refreshButtonText = 'Refresh';
    this.registration = null;
  }
  created() {
    // Listen for swUpdated event and display refresh notification as required.
    document.addEventListener('swUpdated', this.showRefreshUI, {
      once: true
    });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  }
  render() {
    // Avoid warning for missing template
  }
  showRefreshUI(e) {
    // Display a notification inviting the user to refresh/reload the app due
    // to an app update being available.
    // The new service worker is installed, but not yet active.
    // Store the ServiceWorkerRegistration instance for later use.
    const h = this.$createElement;
    this.registration = e.detail;
    this.$notify.info({
      title: 'Update available',
      message: h('div', {
        class: 'sw-update-popup'
      }, [this.notificationText, h('br'), h('button', {
        on: {
          click: e => {
            e.preventDefault();
            this.refreshApp();
          }
        }
      }, this.refreshButtonText)]),
      position: 'bottom-right',
      duration: 0
    });
  }
  refreshApp() {
    // Protect against missing registration.waiting.
    if (!this.registration || !this.registration.waiting) return;
    this.registration.waiting.postMessage('skipWaiting');
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ServiceWorkerUpdatePopup'
})], default_1);
var _default = exports.default = default_1;