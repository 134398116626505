"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.message = '404 Page Not Found';
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Page404'
})], default_1);
var _default = exports.default = default_1;