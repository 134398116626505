"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _products = require("@/api/products");
var _index = _interopRequireDefault(require("@/components/HelpText/index.vue"));
var _tinymceVue = _interopRequireDefault(require("@tinymce/tinymce-vue"));
var _seller = require("@/store/modules/seller");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter name',
        trigger: 'blur'
      }, {
        min: 3,
        message: 'Minimum length should be 3',
        trigger: 'blur'
      }]
    };
    this.productGenFormData = Object.assign({}, _products.defaultProductData);
    this.savedSimpleVariation = [];
    this.savedVariableVariations = [];
    this.loggedInAdmin = null;
  }
  submitForm() {
    this.$refs.productGenFormData.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        this.$message.error('Failed to saved!');
        return false;
      }
    });
  }
  created() {
    // get logged in admin
    this.loggedInAdmin = _seller.SellerModule;
    if (this.isEdit) {
      const productId = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(productId));
    }
    // this.getProductsList();
  }
  async fetchData(id) {
    try {
      const data = await (0, _products.getProductById)(id);
      this.productGenFormData = data;
      if (this.productGenFormData.productsVariation && this.productGenFormData.productsVariation.length) {
        this.productGenFormData.productsVariation.sort(function (a, b) {
          return a.id - b.id;
        });
      }
    } catch (err) {} // eslint-disable-line  no-empty
  }
  toggleProductType() {
    // toggle single product
    if (this.productGenFormData.productType === 'simple') {
      this.productGenFormData.productsVariation = [];
      this.productGenFormData.productsVariation.push(Object.assign({
        enabled: true,
        allowBackOrder: false,
        manageStock: this.productGenFormData.manageStock,
        productId: this.productGenFormData.id || null,
        productsVariationsAttribute: [{
          productId: this.productGenFormData.id || null,
          attributeId: 1,
          attributesValueId: 1
        }],
        mrp: 0,
        unitPrice: 0,
        salePrice: 0,
        sku: '',
        stockQuantity: 0,
        stockStatus: 'In Stock'
      }, ...this.savedSimpleVariation // override with saved one
      ));
    } else {
      this.productGenFormData.productsVariation = this.savedVariableVariations;
    }
  }
  mounted() {
    if (this.productGenFormData.productType === 'simple') {
      this.savedSimpleVariation = this.productGenFormData.productsVariation;
    } else {
      this.savedVariableVariations = this.productGenFormData.productsVariation;
    }
    this.toggleProductType();
  }
  emitFormData() {
    this.toggleProductType();
    this.$emit('updated', this.productGenFormData);
  }
  async saveForm() {
    this.loading = true;
    try {
      if (this.productGenFormData.isOfflineSold && !this.productGenFormData.offlineMarkedByType) {
        this.productGenFormData.offlineMarkedByType = 'Seller';
        this.productGenFormData.offlineMarkedById = this.loggedInAdmin.id;
        this.productGenFormData.offlineMarkedByFullName = this.loggedInAdmin.firstName + this.loggedInAdmin.lastName;
        this.productGenFormData.offlineMarkedTimestamp = new Date().toISOString();
      }
      if (this.productGenFormData.productType === 'simple') {
        // calculate discount percentage
        let discountPercentage = 0;
        let discountAmount = 0;
        if (this.productGenFormData.productsVariation[0].salePrice > 0) {
          discountAmount = this.productGenFormData.productsVariation[0].unitPrice - this.productGenFormData.productsVariation[0].salePrice;
          discountPercentage = discountAmount / this.productGenFormData.productsVariation[0].unitPrice * 100;
          this.productGenFormData.productsVariation[0].discountPercentage = discountPercentage;
          this.productGenFormData.productsVariation[0].discountAmount = discountAmount;
        } else {
          this.productGenFormData.productsVariation[0].discountPercentage = discountPercentage;
          this.productGenFormData.productsVariation[0].discountAmount = discountAmount;
        }
        this.productGenFormData.productsVariation[0] = {
          name: this.productGenFormData.name,
          mrp: this.productGenFormData.productsVariation[0].mrp,
          unitPrice: this.productGenFormData.productsVariation[0].unitPrice,
          salePrice: this.productGenFormData.productsVariation[0].salePrice,
          manageStock: this.productGenFormData.productsVariation[0].manageStock,
          stockStatus: this.productGenFormData.productsVariation[0].stockStatus,
          allowBackOrder: this.productGenFormData.productsVariation[0].allowBackOrder,
          stockQuantity: this.productGenFormData.productsVariation[0].stockQuantity,
          discountPercentage: this.productGenFormData.productsVariation[0].discountPercentage,
          discountAmount: this.productGenFormData.productsVariation[0].discountAmount,
          sku: this.productGenFormData.productsVariation[0].sku,
          id: this.productGenFormData.productsVariation[0].id
        };
        // update status = In Stock if manageStock and stockQuantity > 0
        if (this.productGenFormData.productsVariation[0].manageStock && this.productGenFormData.productsVariation[0].stockQuantity > 0) {
          this.productGenFormData.productsVariation[0].stockStatus = 'In Stock';
        }
        // update status = Out Of Stock if manageStock and stockQuantity <= 0
        if (this.productGenFormData.productsVariation[0].manageStock && this.productGenFormData.productsVariation[0].stockQuantity <= 0) {
          this.productGenFormData.productsVariation[0].stockStatus = 'Out Of Stock';
        }
        // this.productGenFormData.sku = this.productGenFormData.productsVariation[0].sku
      }
      let response = {};
      if (this.isEdit) {
        this.productGenFormData.status = 'Pending';
        // delete this.productGenFormData.mea
        response = await (0, _products.updateProduct)(this.productGenFormData.id, this.productGenFormData);
        // this.$emit("updated", this.productGenFormData);
        this.$emit('added', true);
        this.$emit('reload', true);
      }
      this.$notify({
        title: 'Success',
        message: 'Product saved',
        type: 'success',
        duration: 2000
      });
    } catch (err) {
      this.$notify.error({
        title: 'Failed to save',
        message: err + ''
      });
    } finally {
      this.loading = false;
    }
  }
  resetForm() {
    this.$refs.productGenFormData.resetFields();
  }
  createSlug(title) {
    // Change to lower case
    const titleLower = title.toLowerCase();
    // Trim the last whitespace
    let slug = titleLower.replace(/\s*$/g, '');
    // replace all special characters | symbols with a space
    slug = slug.replace(/[^\w\s]/gi, ' ');
    // Change whitespace to "-"
    slug = slug.replace(/\s+/g, '-');
    this.productGenFormData.slug = slug;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: {}
})], default_1.prototype, "formData", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductGeneralTab',
  components: {
    HelpText: _index.default,
    Editor: _tinymceVue.default
  }
})], default_1);
var _default = exports.default = default_1;