"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _legacy = require("vue-google-charts/legacy");
var _seller = require("@/store/modules/seller");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.animationDuration = 3000;
    this.graphData = [['Date', 'No. of Orders', 'Order Value']];
    this.options = {
      height: 340,
      series: {
        0: {
          color: 'red',
          targetAxisIndex: 0 // Assign this series to the first y-axis
        },
        1: {
          color: 'blue',
          targetAxisIndex: 1 // Assign this series to the second y-axis
        }
      },
      vAxes: {
        0: {
          title: 'No. of Orders' // Label for the first y-axis
        },
        1: {
          title: 'Order Value' // Label for the second y-axis
        }
      },
      legend: {
        position: 'top'
      },
      chartArea: {
        width: '80%',
        height: '70%'
      }
    };
  }
  created() {
    this.getSalesByDate();
  }
  async getSalesByDate() {
    try {
      const response = await (0, _order.orderSalesAndCount)({
        sellerId: _seller.SellerModule.sellerId
      });
      for (const data of response) {
        const row = [];
        row.push(new Date(data.date).toLocaleDateString());
        row.push(+data.count);
        row.push(+data.value);
        this.graphData.push(row);
      }
      // this.graphData.push([new Date().toDateString(), 10, 30])
      // this.graphData.push([new Date(moment().add(1, 'day').toDate()).toDateString(), 10, 50])
      // this.graphData.push([new Date(moment().add(2, 'day').toDate()).toDateString(), 15, 100])
    } catch (error) {}
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: ''
})], default_1.prototype, "dateRange", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'SalesChart',
  components: {
    CountTo: _vueCountTo.default,
    GChart: _legacy.GChart
  }
})], default_1);
var _default = exports.default = default_1;