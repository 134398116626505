"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    class: [{
      "is-active": _vm.isActive
    }],
    on: {
      click: _vm.toggleClick
    }
  }, [_c("svg-icon", {
    attrs: {
      name: "hamburger",
      width: "20",
      height: "20"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;