"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'diamond-cut': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<g _fill="#0a3378"><path pid="0" d="M76 179.1l13.1-9.8 12.2 16.3-13.1 9.8L76 179.1z"/><path pid="1" d="M231.7 81c-1.4-2.9-4.2-4.7-7.3-4.7H75.5c-3.1 0-6 1.8-7.3 4.7l-23.5 49c-1.3 2.8-1 6 .8 8.4l18.4 24.5 13.1-9.8-8.6-11.4h42.4l21.4 85-18.6-24.7-13.1 9.8 42.9 57.1c1.6 2 4 3.3 6.5 3.3s5-1.2 6.5-3.3l98-130.6c1.8-2.4 2.1-5.7.8-8.4L231.7 81zM64.9 125.3l12.5-26 22.2 26H64.9zm28.3-32.7h113.5l-24.6 28.8L155.7 95c-1.6-1.6-3.7-2.4-5.8-2.4-2.1 0-4.2.8-5.8 2.4l-26.4 26.4-24.5-28.8zm69.7 32.7H137l13-13 12.9 13zm-13 105.1l-22.4-88.8h44.6l-22.2 88.8zm17.9-3.7l21.4-85h42.4l-63.8 85zm32.5-101.3l22.2-26 12.5 26h-34.7z"/><path pid="2" d="M305 11h-40.8c-4.5 0-8.2 3.7-8.2 8.2v32.7c0 4.5 3.7 8.2 8.2 8.2h3.1L279.2 84l-13.7 20.6c-1.8 2.8-1.8 6.3 0 9.1l13.3 20-13.3 20c-1.8 2.8-1.8 6.3 0 9.1l13.3 20-13.3 20c-1.8 2.8-1.8 6.3 0 9.1l13.3 20-13.3 20c-.9 1.3-1.4 2.9-1.4 4.5v32.7c-4.5 0-8.2 3.7-8.2 8.2V330c0 4.5 3.7 8.2 8.2 8.2h16.3v16.3h-8.2c-4.5 0-8.2 3.7-8.2 8.2v98c0 22.5 18.3 40.8 40.8 40.8s40.8-18.3 40.8-40.8v-98c0-4.5-3.7-8.2-8.2-8.2h-8.2v-18.4C407.6 324.2 468 256.6 468 174.9c0-90-73.2-163.3-163.3-163.3l.3-.6zm24.5 448.9c0 13.5-11 24.5-24.5 24.5s-24.5-11-24.5-24.5v-89.8h49v89.8zm-32.7-106.1v-16.3h8.2c2.8 0 5.5-.2 8.2-.4v16.7h-16.4zm8.2-32.6h-32.7v-16.3H305c72 0 130.6-58.6 130.6-130.6S377 43.7 305 43.7h-32.7V27.3H305c81 0 146.9 66 146.9 146.9s-65.9 147-146.9 147zm-9.5-94.3l-13.3-20 13.3-20c1.8-2.8 1.8-6.3 0-9.1l-13.3-20 13.3-20c1.8-2.8 1.8-6.3 0-9.1l-13.3-20 13.3-20c1.6-2.4 1.8-5.6.5-8.2l-10.4-20.8H305c2.8 0 5.5.2 8.2.4V288c-2.7.2-5.4.4-8.2.4h-24.5v-30.2l14.9-22.4c1.8-2.8 1.8-6.3 0-9.1l.1.2zm34 58.9V62.7c51.3 11.3 89.8 57 89.8 111.6s-38.5 100.3-89.8 111.5z"/></g>'
  }
});