"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _router = _interopRequireDefault(require("./router"));
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _elementUi = require("element-ui");
var _seller = require("@/store/modules/seller");
var _permission = require("@/store/modules/permission");
var _lang = _interopRequireDefault(require("@/lang"));
var _settings = _interopRequireDefault(require("./settings"));
// Internationalization

_nprogress.default.configure({
  showSpinner: false
});
const whiteList = ['/login', '/recover-password', '/auth-redirect', '/reset-password/1a7dbabf-06a8-47bb-a907-03a21413585a'];
const getPageTitle = key => {
  const hasKey = _lang.default.te(`route.${key}`);
  if (hasKey) {
    const pageName = _lang.default.t(`route.${key}`);
    return `${pageName} - ${_settings.default.title}`;
  }
  return `${_settings.default.title}`;
};
_router.default.beforeEach(async (to, _, next) => {
  // Start progress bar
  _nprogress.default.start();
  // Determine whether the user has logged in
  if (_seller.SellerModule.token) {
    if (to.path === '/login') {
      // If is logged in, redirect to the home page
      next({
        path: '/'
      });
      _nprogress.default.done();
    } else {
      // Check whether the user has obtained his permission roles
      if (_seller.SellerModule.roles.length === 0) {
        try {
          // Note: roles must be a object array! such as: ['superadmin','admin'] or ['executive']
          await _seller.SellerModule.GetSellerInfo();
          const roles = _seller.SellerModule.roles;
          // Generate accessible routes map based on role
          _permission.PermissionModule.GenerateRoutes(roles);
          // Dynamically add accessible routes
          _router.default.addRoutes(_permission.PermissionModule.dynamicRoutes);
          // Hack: ensure addRoutes is complete
          // Set the replace: true, so the navigation will not leave a history record
          next({
            ...to,
            replace: true
          });
        } catch (err) {
          // Remove token and redirect to login page
          _seller.SellerModule.ResetToken();
          _elementUi.Message.error(err.toString() || 'Has Error');
          next(`/login?redirect=${to.path}`);
          _nprogress.default.done();
        }
      } else {
        next();
      }
    }
  } else {
    // Has no token
    if (whiteList.indexOf(to.path) !== -1) {
      // In the free login whitelist, go directly
      // next('/recover-password')
      next();
    } else {
      // Other pages that do not have permission to access are redirected to the login page.
      if (to.path === '/recover-password') {
        next('/recover-password');
      } else if (to.path === '/reset-password/1a7dbabf-06a8-47bb-a907-03a21413585a') {
        next('/reset-password/1a7dbabf-06a8-47bb-a907-03a21413585a');
      }
      next(`/login?redirect=${to.path}`);
      _nprogress.default.done();
    }
  }
});
_router.default.afterEach(to => {
  // Finish progress bar
  _nprogress.default.done();
  // set page title
  document.title = getPageTitle(to.meta.title);
});