"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.articleStatusFilter = void 0;
Object.defineProperty(exports, "parseTime", {
  enumerable: true,
  get: function () {
    return _utils.parseTime;
  }
});
exports.uppercaseFirstChar = void 0;
var _utils = require("@/utils");
// Set utils function parseTime to filter

// Filter for article status
const articleStatusFilter = status => {
  const statusMap = {
    published: 'success',
    draft: 'info',
    deleted: 'danger'
  };
  return statusMap[status];
};
// Filter to uppercase the first character
exports.articleStatusFilter = articleStatusFilter;
const uppercaseFirstChar = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
exports.uppercaseFirstChar = uppercaseFirstChar;