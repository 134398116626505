"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'master': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M474.5 291.1h-9.1V119.8c0-3.6-2.2-7-5.6-8.3-3.4-1.4-7.3-.7-10 1.9l-93.9 92.8-93.9-92.8c-3.6-3.5-9.4-3.5-12.9 0l-93.9 92.8-93.9-92.8c-2.6-2.6-6.6-3.3-10-1.9-3.4 1.4-5.6 4.7-5.6 8.3v171.3h-9.1c-5 0-9.1 4-9.1 9v36.1c0 5 4.1 9 9.1 9H64v45.1c0 5 4.1 9 9.1 9h365c5 0 9.1-4 9.1-9v-45.1h27.4c5 0 9.1-4 9.1-9v-36.1c-.2-5-4.2-9-9.2-9zM63.9 141.6l84.8 83.7c3.6 3.5 9.4 3.5 12.9 0l93.9-92.8 93.9 92.8c3.6 3.5 9.4 3.5 12.9 0l84.8-83.7v149.5H63.9V141.6zm364.9 239.6H82.1v-36.1h346.7v36.1zm36.5-54.1H45.6v-18h419.7v18z"/><path pid="1" d="M255.5 182.9c-20.1 0-36.5 16.1-36.5 36.1v18c0 19.9 16.3 36.1 36.5 36.1s36.5-16.1 36.5-36v-18c0-20-16.4-36.2-36.5-36.2zm18.2 54.1c0 9.9-8.2 18-18.3 18s-18.3-8.1-18.3-18v-18c0-9.9 8.2-18 18.3-18s18.3 8.1 18.3 18v18zM255.5 462.3c-51.7 0-101.4-18.7-139.9-52.8L103.4 423c41.8 37 95.8 57.4 152 57.4s110.2-20.3 152-57.4l-12.2-13.5c-38.4 34-88 52.8-139.7 52.8zM255 47.7c60.2 0 117.5 25.6 157.3 70.2L426 106c-43.4-48.5-105.7-76.3-171-76.3-64.9 0-126.8 27.4-170 75.1l13.6 12c39.7-43.9 96.7-69.1 156.4-69.1z"/>'
  }
});