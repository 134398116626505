"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
let default_1 = class extends _vuePropertyDecorator.Vue {
  get language() {
    return _app.AppModule.language;
  }
  handleSetLanguage(lang) {
    this.$i18n.locale = lang;
    _app.AppModule.SetLanguage(lang);
    this.$message({
      message: 'Switch Language Success',
      type: 'success'
    });
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Login'
})], default_1);
var _default = exports.default = default_1;