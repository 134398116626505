"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateAttribute = exports.getAttributesTree = exports.getAttributes = exports.getAttributeById = exports.fileTransferHeaders = exports.deleteAttribute = exports.defaultAttributeData = exports.createAttribute = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultAttributeData = exports.defaultAttributeData = {
  id: 0,
  enabled: true,
  name: ''
};
const getAttributes = params => (0, _request.default)({
  url: '/attributes',
  method: 'get',
  params
});
exports.getAttributes = getAttributes;
const getAttributesTree = data => (0, _request.default)({
  url: '/attributes/tree',
  method: 'post',
  data
});
exports.getAttributesTree = getAttributesTree;
const getAttributeById = id => (0, _request.default)({
  url: `/attributes/${id}`,
  method: 'get'
});
exports.getAttributeById = getAttributeById;
const updateAttribute = (id, data) => (0, _request.default)({
  url: `/attributes/${id}`,
  method: 'patch',
  data
});
exports.updateAttribute = updateAttribute;
const deleteAttribute = id => (0, _request.default)({
  url: `/attributes/${id}`,
  method: 'delete'
});
exports.deleteAttribute = deleteAttribute;
const createAttribute = data => (0, _request.default)({
  url: '/attributes/',
  method: 'post',
  data
});
exports.createAttribute = createAttribute;