"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _ = _interopRequireDefault(require("@/assets/401-images/401.gif"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.errGif = _.default + '?' + +new Date();
    this.ewizardClap = 'https://wpimg.wallstcn.com/007ef517-bafd-4066-aae4-6883632d9646';
    this.dialogVisible = false;
  }
  back() {
    if (this.$route.query.noGoBack) {
      this.$router.push({
        path: '/dashboard'
      });
    } else {
      this.$router.go(-1);
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Page401'
})], default_1);
var _default = exports.default = default_1;