"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _settings = require("@/store/modules/settings");
const version = require('element-ui/package.json').version; // element-ui version from node_modules
const ORIGINAL_THEME = '#409EFF'; // default color
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.chalk = ''; // The content of theme-chalk css
    this.theme = '';
  }
  get defaultTheme() {
    return _settings.SettingsModule.theme;
  }
  onDefaultThemeChange(value) {
    this.theme = value;
  }
  async onThemeChange(value) {
    if (!value) return;
    const oldValue = this.chalk ? this.theme : ORIGINAL_THEME;
    const themeCluster = this.getThemeCluster(value.replace('#', ''));
    const originalCluster = this.getThemeCluster(oldValue.replace('#', ''));
    const message = this.$message({
      message: '  Compiling the theme',
      customClass: 'theme-message',
      type: 'success',
      duration: 0,
      iconClass: 'el-icon-loading'
    });
    if (!this.chalk) {
      const url = `https://unpkg.com/element-ui@${version}/lib/theme-chalk/index.css`;
      await this.getCSSString(url, 'chalk');
    }
    const getHandler = (variable, id) => {
      return () => {
        const originalCluster = this.getThemeCluster(ORIGINAL_THEME.replace('#', ''));
        const newStyle = this.updateStyle(this[variable], originalCluster, themeCluster);
        let styleTag = document.getElementById(id);
        if (!styleTag) {
          styleTag = document.createElement('style');
          styleTag.setAttribute('id', id);
          document.head.appendChild(styleTag);
        }
        styleTag.innerText = newStyle;
      };
    };
    const chalkHandler = getHandler('chalk', 'chalk-style');
    chalkHandler();
    let styles = [].slice.call(document.querySelectorAll('style'));
    styles = styles.filter(style => {
      const text = style.innerText;
      return new RegExp(oldValue, 'i').test(text) && !/Chalk Variables/.test(text);
    });
    styles.forEach(style => {
      const {
        innerText
      } = style;
      if (typeof innerText !== 'string') return;
      style.innerText = this.updateStyle(innerText, originalCluster, themeCluster);
    });
    this.$emit('change', value);
    message.close();
  }
  updateStyle(style, oldCluster, newCluster) {
    let newStyle = style;
    oldCluster.forEach((color, index) => {
      newStyle = newStyle.replace(new RegExp(color, 'ig'), newCluster[index]);
    });
    return newStyle;
  }
  getCSSString(url, variable) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          this[variable] = xhr.responseText.replace(/@font-face{[^}]+}/, '');
          resolve();
        }
      };
      xhr.open('GET', url);
      xhr.send();
    });
  }
  getThemeCluster(theme) {
    const tintColor = (color, tint) => {
      let red = parseInt(color.slice(0, 2), 16);
      let green = parseInt(color.slice(2, 4), 16);
      let blue = parseInt(color.slice(4, 6), 16);
      if (tint === 0) {
        // when primary color is in its rgb space
        return [red, green, blue].join(',');
      } else {
        red += Math.round(tint * (255 - red));
        green += Math.round(tint * (255 - green));
        blue += Math.round(tint * (255 - blue));
        return `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
      }
    };
    const shadeColor = (color, shade) => {
      let red = parseInt(color.slice(0, 2), 16);
      let green = parseInt(color.slice(2, 4), 16);
      let blue = parseInt(color.slice(4, 6), 16);
      red = Math.round((1 - shade) * red);
      green = Math.round((1 - shade) * green);
      blue = Math.round((1 - shade) * blue);
      return `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
    };
    const clusters = [theme];
    for (let i = 0; i <= 9; i++) {
      clusters.push(tintColor(theme, Number((i / 10).toFixed(2))));
    }
    clusters.push(shadeColor(theme, 0.1));
    return clusters;
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('defaultTheme', {
  immediate: true
})], default_1.prototype, "onDefaultThemeChange", null);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('theme')], default_1.prototype, "onThemeChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ThemePicker'
})], default_1);
var _default = exports.default = default_1;