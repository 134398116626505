"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCategory = exports.getCategoryById = exports.getCategoriesTreeForAdmin = exports.getCategoriesTree = exports.getCategories = exports.fileTransferHeaders = exports.deleteCategory = exports.defaultCategoryData = exports.createCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const fileTransferHeaders = exports.fileTransferHeaders = {};
const defaultCategoryData = exports.defaultCategoryData = {
  id: 0,
  enabled: true,
  name: '',
  subTitle: '',
  slug: '',
  icon: '',
  image: '',
  banner: '',
  description: '',
  footerDescription: '',
  priority: 0,
  featured: false,
  parent: null,
  metaTitle: '',
  metaDescription: '',
  metaKeywords: '',
  canonicalTag: '',
  displayInFooter: false,
  page: '',
  productId: null,
  product: {
    id: undefined
  },
  brandId: null,
  brand: {
    id: undefined
  }
};
const getCategories = params => (0, _request.default)({
  url: '/categories',
  method: 'get',
  params
});
exports.getCategories = getCategories;
const getCategoriesTree = () => (0, _request.default)({
  url: '/categories/list/tree',
  method: 'get'
});
exports.getCategoriesTree = getCategoriesTree;
const getCategoryById = id => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'get'
});
exports.getCategoryById = getCategoryById;
const updateCategory = (id, data) => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'patch',
  data
});
exports.updateCategory = updateCategory;
const deleteCategory = id => (0, _request.default)({
  url: `/categories/${id}`,
  method: 'delete'
});
exports.deleteCategory = deleteCategory;
const createCategory = data => (0, _request.default)({
  url: '/categories',
  method: 'post',
  data
});
exports.createCategory = createCategory;
const getCategoriesTreeForAdmin = () => (0, _request.default)({
  url: '/categories/listForAdmin/tree',
  method: 'get'
});
exports.getCategoriesTreeForAdmin = getCategoriesTreeForAdmin;