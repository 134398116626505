"use strict";

var _interopRequireDefault = require("/home/yaambs/Desktop/SGL-Seller/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'Price-not-enterted': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M484.7 100.5c0-.1.1-.2.1-.3.1-.5.1-.2.1-.6v-.7c0-.5 0-.4-.1-.7-.1-.5-.1-.4-.2-.7-.2-.6-.2-.4-.3-.7-.2-.4-.2-.3-.4-.6-.1-.1-.1-.2-.2-.2-60-72.8-56-68.1-56.8-68.8-.5-.4-1.2-.7-1.9-.9-.3-.1-.5-.1-.8-.1h-.3c-222.2 0-208.1-.1-209 .1-.5.1-.9.3-1 .3-.6.3-1.2.7-1.6 1.2l-.1.1c-58.5 71-56.3 68.2-56.7 69.1 0 .1-.2.6-.3.7 0 .1-.1.3-.1.4 0 .2-.1.2-.1.7v.8c0 .1 0 .1.1.6.1.2 0 0 .2.5.1.4.1.2.3.7.1.1.2.4.6.8C322 288.6 316.5 282.6 317.3 283.1c.5.4 1.2.6 1.7.7 1 .2-2.6.1 72.3.1 40.7 0 73.8 33.1 73.8 73.8s-33.1 73.8-73.8 73.8h-100c-31.7 0-57.4-25.8-57.4-57.4V261.8c0-33.9-27.6-61.5-61.5-61.5s-61.5 27.6-61.5 61.5v5.2l-57.5 52.7c-1 .9-1.6 2.2-1.6 3.6v157.5c0 2.7 2.2 4.9 4.9 4.9h118.2c2.7 0 4.9-2.2 4.9-4.9V323.3c0-1.4-.6-2.7-1.6-3.6L120.6 267v-5.2c0-28.5 23.2-51.7 51.7-51.7s51.7 23.2 51.7 51.7v112.4c0 37.1 30.2 67.3 67.3 67.3h100.1c46.1 0 83.7-37.5 83.7-83.7s-37.5-83.7-83.7-83.7H331l152.6-171.7h0c.4-.4.5-.7.6-1 .1-.2.1-.1.3-.7.2 0 .2-.1.2-.2h0zM61.5 475.9v-14.8h108.3v14.8H61.5zm108.3-24.6H61.5V325.5l54.2-49.6 54.2 49.6-.1 125.8h0zM255.4 104h129.4l-64.7 161.7L255.4 104zm135.1-13.1l-58-54.8h83.8l-25.8 54.8zm-10.8 3.3H260.5l59.6-56.3 59.6 56.3zm-130-3.3l-25.8-54.8h83.8l-58 54.8zm175.5-50.6l44.4 53.9h-69.8l25.4-53.9zm-210.2 0l25.4 53.9h-69.8L215 40.3zm29.8 63.7L305 254.7 171.1 104h73.7zm90.3 150.7L395.4 104H469L335.1 254.7z" _stroke="#000" stroke-width="7" stroke-miterlimit="10"/><circle pid="1" cx="384.8" cy="355.4" r="50.6" _fill="none" _stroke="#000" stroke-width="15" stroke-miterlimit="10"/><path pid="2" d="M419 355.4c0 4.4-3.6 8-8 8h-52.2c-4.4 0-8-3.6-8-8s3.6-8 8-8H411c4.4 0 8 3.6 8 8z"/><path pid="3" d="M148.2 335.6v5.4h-25.1c4.8 3.8 8.1 9.7 8.9 16.2h16.3v5.4H132c-1.3 12.1-11.5 21.5-24 21.5H95.1l36.4 41.3-4 3.6-44.3-50.1H108c9.6 0 17.4-7 18.7-16.2h-43v-5.4h42.9c-1.3-9.2-9.2-16.2-18.7-16.2H83.7v-5.4l64.5-.1h0z" _stroke="#000" stroke-width="6" stroke-miterlimit="10"/>'
  }
});