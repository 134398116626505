"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _errorLog = require("@/store/modules/error-log");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.dialogTableVisible = false;
  }
  get errorLogs() {
    return _errorLog.ErrorLogModule.logs;
  }
  clearAll() {
    this.dialogTableVisible = false;
    _errorLog.ErrorLogModule.ClearErrorLog();
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ErrorLog'
})], default_1);
var _default = exports.default = default_1;