"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
const WIDTH = 992; // refer to Bootstrap's responsive design
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  get device() {
    return _app.AppModule.device;
  }
  get sidebar() {
    return _app.AppModule.sidebar;
  }
  onRouteChange() {
    if (this.device === _app.DeviceType.Mobile && this.sidebar.opened) {
      _app.AppModule.CloseSideBar(false);
    }
  }
  beforeMount() {
    window.addEventListener('resize', this.resizeHandler);
  }
  mounted() {
    const isMobile = this.isMobile();
    if (isMobile) {
      _app.AppModule.ToggleDevice(_app.DeviceType.Mobile);
      _app.AppModule.CloseSideBar(true);
    }
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
  }
  isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < WIDTH;
  }
  resizeHandler() {
    if (!document.hidden) {
      const isMobile = this.isMobile();
      _app.AppModule.ToggleDevice(isMobile ? _app.DeviceType.Mobile : _app.DeviceType.Desktop);
      if (isMobile) {
        _app.AppModule.CloseSideBar(true);
      }
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)('$route')], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ResizeMixin'
})], default_1);
var _default = exports.default = default_1;