"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    attrs: {
      id: "product-info"
    }
  }, [_c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-steps", {
    attrs: {
      space: 200,
      active: _vm.activeIndex,
      "align-center": ""
    }
  }, _vm._l(_vm.stepsOptions, function (step, index) {
    return _c("el-step", {
      key: index,
      attrs: {
        title: step.title,
        status: step.status,
        icon: step.icon
      },
      nativeOn: {
        click: function ($event) {
          return _vm.stepClick(index);
        }
      }
    });
  }), 1), _c("el-divider", [_c("i", {
    staticClass: "el-icon-star-on"
  })]), _c("keep-alive", [_c(_vm.stepsOptions[_vm.activeIndex].component, {
    tag: "component",
    attrs: {
      "form-data": _vm.formData,
      "is-edit": _vm.isEdit
    },
    on: {
      updated: function ($event) {
        _vm.formData = $event;
        _vm.checkIfVariationsTabRequired();
      },
      edited: function ($event) {
        _vm.isEdit = $event;
      },
      added: function ($event) {
        _vm.isEdit = $event;
        _vm.stepClick(_vm.activeIndex + 1);
      },
      reload: function ($event) {
        return _vm.fetchData();
      }
    }
  })], 1), _c("div", {
    staticClass: "next-prev-container"
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "pull-right",
    attrs: {
      type: "primary",
      plain: "",
      disabled: _vm.activeIndex === _vm.stepsOptions.length - 1
    },
    on: {
      click: function ($event) {
        return _vm.stepClick(_vm.activeIndex + 1);
      }
    }
  }, [_vm._v(" Next "), _c("i", {
    staticClass: "el-icon-arrow-right"
  })]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "pull-right",
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      type: "primary",
      plain: "",
      disabled: _vm.activeIndex === 0
    },
    on: {
      click: function ($event) {
        return _vm.stepClick(_vm.activeIndex - 1);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-left"
  }), _vm._v("  Prev ")])], 1)], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;